import React, { useEffect, useState } from 'react'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import { getSavedPlacesList, getUser, getWhiteLabelingList } from '../state/user/selectors'
import { getLocationByPlaceId } from '../utils/travel'
import { GOOGLE_API_KEY } from '../constants'
import { ISavedPlaces, ITransportModePlace } from '../types/data'
import { Cross, HearthActiveIcon, HearthDefaultIcon } from './common/Svg'
import SavedPlaceOverlay from './SavedPlaceOverlay'
import { countryCodeMap } from '../utils/countryCode'

interface IProps {
    placeholder: string
    defaultValue?: { placeId: string; lon: string; lat: string; name: string; }
    defaultValueLeg?: string
    setPlace?: ({ placeId, lon, lat, name }: { placeId: string, lon: string, lat: string, name: string }) => void
    places?: ITransportModePlace[]
    dontEmpty?: boolean
    containerClassName?: string
    savePlace?: boolean
    showHeart?: boolean
    selectRef?: React.MutableRefObject<any>
    onChange?: () => void
}

export default function GoogleAutoComplete({ defaultValueLeg, showHeart = true, savePlace, placeholder, setPlace, defaultValue, places, containerClassName, selectRef, onChange, dontEmpty = false }: IProps) {
    const { user } = useSelector(getUser)
    const { savedPlaces } = useSelector(getSavedPlacesList)
    const [selectedPlace, setSelectedPlace] = useState<string | number | undefined>('')
    const [showModal, setShowModal] = useState<boolean>(false)
    const [isSavedPlace, setIsSavedPlace] = useState<boolean>(false)
    const sortedPlaces = places?.sort((a, b) => {
        if (a.placeName < b.placeName) {
            return -1 // a comes first
        }
        if (a.placeName > b.placeName) {
            return 1 // b comes first
        }
        return 0 // names are equal
    })

    const { whiteLabeling } = useSelector(getWhiteLabelingList)
    const [clearInput, setClearInput] = useState<boolean>(false)
    const [options, setOptions] = useState(sortedPlaces?.slice(0, 5))

    useEffect(() => {
        const isPlaceSaved = savedPlaces.find((place) => place.placeName === (defaultValue?.name || defaultValueLeg))
        if (isPlaceSaved) {
            setSelectedPlace(isPlaceSaved?.placeName)
        }
        // eslint-disable-next-line
    }, [savedPlaces, defaultValue])

    useEffect(() => {
        const isPlaceSaved = savedPlaces.some((place) => place.placeName === (defaultValue?.name || defaultValueLeg))
        setIsSavedPlace(isPlaceSaved)
    }, [selectedPlace, savedPlaces, defaultValue?.name, defaultValueLeg])

    const handleSelect = async ({ label, value }: {label: string, value: any}) => {
        if (setPlace) {
            setPlace({
                placeId: dontEmpty ? value.place_id : '',
                name: dontEmpty ? label : '',
                lat: '',
                lon: '',
            })
        }

        if (!value.lat || !value.lon) {
            const location = await getLocationByPlaceId(value.place_id)

            if (setPlace) {
                setPlace({
                    placeId: value.place_id,
                    name: label,
                    ...location,
                })
            }
        } else if (setPlace) {
            setPlace({
                placeId: value.place_id,
                name: label,
                lat: value.lat,
                lon: value.lon,
            })
        }

        onChange?.()
    }

    const selectProps = {
        ref: (ref: any) => {
            if (selectRef) {
                selectRef.current = ref
            }
        },
        // @ts-ignore
        onChange: handleSelect,
        placeholder,
        // @ts-ignore
        value: clearInput ? '' : (defaultValue?.name || defaultValueLeg ? { label: (defaultValue?.name || defaultValueLeg) || undefined, value: (defaultValue?.name || defaultValueLeg) || undefined } : undefined),
        isClearable: true,
        onMenuOpen: () => {
            setClearInput(true)
        },
        onMenuClose: () => {
            setClearInput(false)
        },
        styles: {
            // @ts-ignore
            control: (base) => ({
                ...base,
                width: '100%',
                height: 48,
                borderWidth: 1,
                borderColor: whiteLabeling?.avocado,
                borderRadius: 8,
                paddingRight: 6,
                paddingLeft: 6,
            }),
            indicatorsContainer: () => ({
                display: 'none',
            }),
        },
    }

    const onChangeValueTag = async (item: ISavedPlaces) => {
        const location = await getLocationByPlaceId(item?.placeId || '')

        if (setPlace) {
            setPlace({
                placeId: item?.placeId || '',
                name: item?.placeName || '',
                ...location,
            })
        }
        setSelectedPlace(item?.placeName)
        setClearInput(false)
    }

    const onClearValueTag = () => {
        if (setPlace) {
            setPlace({
                placeId: '',
                name: '',
                lat: '',
                lon: '',
            })
        }
        setSelectedPlace('')
        setClearInput(true)
    }

    const heartPress = () => {
        if ((defaultValue?.name || defaultValueLeg) && !isSavedPlace) {
            setShowModal(true)
        }
    }

    const travelData = JSON.parse(localStorage.getItem('travelData') || '{}')

    const getCountry = (placeName: string) => placeName.split(',').pop()?.trim()

    const getCountryCode = (countryName: string) => countryCodeMap[countryName.trim()]

    const startPlaceName = getCountry(travelData?.startPlaceName || '')
    const endPlaceName = getCountry(travelData?.endPlaceName || '')

    const countryCode = startPlaceName === endPlaceName ? getCountryCode(startPlaceName || '') : undefined

    return (
        <div className={containerClassName}>
            {!savePlace && user.company?.showSavedPlaces && (
                <div className="flex overflow-x-auto w-full">
                    {savedPlaces?.map((item) => (
                        <button
                            onClick={() => onChangeValueTag(item)}
                            className={`h-[32px] rounded-[30px] flex items-center justify-center cursor-pointer mb-[8px] mr-2 ${(selectedPlace === item?.placeName && isSavedPlace) ? 'px-3 bg-cavolo' : 'px-5 bg-pistachio border-2 border-spinach'} `}
                            key={item?.id}>

                            <span className={`font-semibold ${(selectedPlace === item?.placeName && isSavedPlace) ? 'text-white' : 'text-cavolo'} text-12`}>
                                {item?.name}
                            </span>

                            {(selectedPlace === item?.placeName && isSavedPlace) ? (
                                <div className="ml-1.5" onClick={(e) => {
                                    e.stopPropagation()
                                    if (selectedPlace === item?.placeName && isSavedPlace) {
                                        onClearValueTag()
                                    }
                                }}>
                                    <Cross width={10} height={10} />
                                </div>
                            ) : null}
                        </button>
                    ))}
                </div>
            )}

            <div className="relative">
                {((sortedPlaces?.length || 0) > 0) ? (
                    <Select
                        {...selectProps}
                        isSearchable
                        // @ts-ignore
                        options={options.map((i) => ({ value: { ...i, place_id: i.placeId }, label: i.placeName }))}
                        onInputChange={(inputValue) => {
                            if (inputValue) {
                                const filteredOptions = sortedPlaces?.filter((i) => i.placeName.toLowerCase().includes(inputValue.toLowerCase()))
                                setOptions(filteredOptions?.slice(0, 5) || [])
                            } else {
                                setOptions(sortedPlaces?.slice(0, 5))
                            }
                        }}
                    />
                ) : (
                    <GooglePlacesAutocomplete
                        apiOptions={{
                            libraries: [
                                'core',
                                'maps',
                                'places',
                                'geocoding',
                                'routes',
                                'marker',
                                'geometry',
                                'elevation',
                                'streetView',
                                'journeySharing',
                                'drawing',
                                'visualization',
                            ],
                            language: 'en',
                        }}
                        // @ts-ignore
                        selectProps={{ ...selectProps }}
                        apiKey={GOOGLE_API_KEY}
                        autocompletionRequest={{
                            componentRestrictions: user?.company?.travelTrackingType === 'event'
                                ? { country: countryCode ?? '' }
                                : user?.company?.trackTravelCountries
                                    ? { country: user?.company?.trackTravelCountries.split(',') }
                                    : undefined,
                        }}
                    />
                )}

                {showHeart && user?.company?.showSavedPlaces && (
                    <div onClick={heartPress}
                        className={`${((defaultValue?.name || defaultValueLeg) && !isSavedPlace) && 'cursor-pointer'} absolute right-0 top-0 flex items-center justify-center w-[48px] h-[48px]`}>
                        {isSavedPlace ? <HearthActiveIcon /> : <HearthDefaultIcon />}
                    </div>
                )}
            </div>

            <SavedPlaceOverlay
                showModal={showModal}
                setShowModal={setShowModal}
                enteredViaMoreButton
                editItem={defaultValue}
                viaHeart />
        </div>
    )
}
