import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import AccountSidebar from '../../components/account/AccountSidebar'
import Input from '../../components/common/Input'
import { IUpdateUserForm } from '../../types/data'
import { getUser } from '../../state/user/selectors'
import SecondButton from '../../components/common/SecondButton'
import { required } from '../../utils/validators'
import CustomPhoneInput from '../../components/common/CustomPhoneInput'
import { fetchUpdateUserSetting, fetchUser } from '../../state/user/actions'

export default function YourInfo() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { user, isSso } = useSelector(getUser)
    const [loading, setLoading] = useState<boolean>(false)
    const [countryCode, setCountryCode] = useState<string>(user?.country || '')
    const [t] = useTranslation('global')

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `${window.location.pathname}${window.location.search}`, title: 'Your info' })
    }, [])

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        // eslint-disable-next-line
    }, [])

    const onSubmit = async (data: IUpdateUserForm) => {
        setLoading(true)
        const updatedData = {
            ...data,
            country: countryCode,
        }
        await dispatch(fetchUpdateUserSetting(updatedData))
        await dispatch(fetchUser)
        setLoading(false)
    }

    const initialValues: IUpdateUserForm = {
        firstName: user?.firstName,
        lastName: user?.lastName,
        displayName: user?.displayName,
        timezone: user?.timezone,
        phoneNumber: user?.phoneNumber,
    }

    return (
        <AccountSidebar>
            <div className="tablet:mb-[120px] mb-[80px] mobile:ml-10 mx-4 mobile:mr-10 mobile:w-[562px]">
                <h1 className="heading-uppercase mobile:text-36 text-26 font tracking-tight font-medium text-darken mb-[26px]">
                    {t('account.update-your-information')}
                </h1>

                <div className="mobile:w-[343px]">
                    <Formik initialValues={initialValues} onSubmit={onSubmit}>
                        <Form>
                            <Input
                                name="firstName"
                                label={t('global.first-name')}
                                type="text"
                                disabled={isSso}
                                validate={required(t)} />

                            <Input
                                name="lastName"
                                label={t('global.surname')}
                                type="text"
                                disabled={isSso}
                                validate={required(t)} />

                            <Input
                                name="displayName"
                                label={t('account.display-name')}
                                type="text"
                                disabled={isSso} />

                            <div className="flex items-center mb-1.5">
                                <h1 className="text-14 font-medium text-black">
                                    {t('global.phone-number')}
                                </h1>

                                <span className="text-14 text-grey01">
                                &nbsp;{t('account.optional')}
                                </span>
                            </div>

                            <CustomPhoneInput
                                defaultValue={user?.phoneNumber}
                                name="phoneNumber"
                                setCountryCode={setCountryCode}
                                countryCode={countryCode}
                                className="mb-4"
                            />

                            <div>
                                <h1 className="text-14 font text-darken">
                                    {t('global.password')}
                                </h1>

                                <div onClick={() => {
                                    if (!isSso) navigate('/me/account-settings/change-password')
                                }} className="cursor-pointer flex items-center mobile:w-[343px] h-[48px] border border-avocado rounded-lg mt-[6px] bg-[#fff]">
                                    <h1 className="pl-[11px] tracking-[-0.12rem]">
                                        ••••••••••
                                    </h1>
                                </div>
                            </div>

                            <h1 className="mt-[4px] text-14 text-[#707070] font-normal tracking-[-0.02rem]">
                                {t('account.15-days-until-next-reset')}
                            </h1>

                            <SecondButton
                                disabled={isSso}
                                loading={loading}
                                text={t('account.save-changes')}
                                className="mt-[38px] w-full h-[48px] font-semibold bg-rocket rounded-md text-14 text-pistachio hover:bg-spinach hover:ease-in hover:duration-200"
                            />
                        </Form>
                    </Formik>

                    <div className="text-center">
                        <button onClick={() => navigate('/me/account-settings/delete-account')} className="mt-6 font-semibold tracking-tight underline underline-offset-2 text-12 text-rocket">
                            {t('account.delete-my-lowr-account')}
                        </button>
                    </div>
                </div>
            </div>
        </AccountSidebar>
    )
}
