import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import ReactGA from 'react-ga4'
import QuizCompleteModal from '../../components/quiz/QuizCompleteModal'
import QuizQuitOverlayModal from '../../components/quiz/QuizQuitOverlayModal'
import { getQuiz } from '../../state/quiz/selectors'
import QuizDetailHeader from '../../components/quiz/QuizDetailHeader'
import { fetchQuiz, postQuizAnswer } from '../../state/quiz/actions'
import { IAnswerData, IQuestion } from '../../types/data'
import QuizDetailFooter from '../../components/quiz/QuizDetailFooter'
import { Radio } from '../../components/common/Radio'
import SponsoredByBlock from '../../components/SponsoredByBlock'
import { getUser, getWhiteLabelingList } from '../../state/user/selectors'

export default function Quiz() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { quiz, loading, quizAnswerResponse } = useSelector(getQuiz)
    const { quizId } = useParams()
    const { user } = useSelector(getUser)
    const { whiteLabeling } = useSelector(getWhiteLabelingList)

    const [activeQuestion, setActiveQuestion] = useState<IQuestion | null>(null)
    const [activeQuestionIndex, setActiveQuestionIndex] = useState<number>(0)
    const [totalAnswers, setTotalAnswers] = useState<IAnswerData[]>([])
    const [showAnswer, setShowAnswer] = useState<boolean>(false)

    const [selectedAnswers, setSelectedAnswers] = useState<number[]>([])
    const [showQuizCompleteModal, setShowQuizCompleteModal] = useState<boolean | undefined>(false)
    const [showQuizQuitOverlayModal, setShowQuizQuitOverlayModal] = useState(false)

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `${window.location.pathname}${window.location.search}`, title: 'Quiz' })
    }, [])

    useEffect(() => {
        dispatch(fetchQuiz(quizId || 0))

        // eslint-disable-next-line
    }, [quizId])

    useEffect(() => {
        if (quiz && quiz.questions && !activeQuestion) setActiveQuestion(quiz.questions[0])

        // eslint-disable-next-line
    }, [quiz])

    const handleChoiceAnswer = useCallback((value: string | number) => {
        const answerId = Number(value)

        setSelectedAnswers((oldState) => {
            const newValue = [...oldState, answerId]

            if (oldState.includes(answerId)) return oldState.filter((id) => id !== answerId)
            if (activeQuestion?.correctAnswersCount === 1) return [answerId]
            if ((activeQuestion?.correctAnswersCount || 0) >= newValue.length) return newValue
            return oldState
        })

        // eslint-disable-next-line
    }, [activeQuestion])

    const onSubmit = () => {
        if (!showAnswer) {
            setShowAnswer(true)
            return
        }

        const newTotalAnswers = [...totalAnswers, { question: activeQuestion?.id || 0, answers: selectedAnswers }]

        setTotalAnswers(newTotalAnswers)
        setSelectedAnswers([])
        setShowAnswer(false)

        if (activeQuestionIndex + 1 === quiz?.questions?.length) {
            dispatch(
                postQuizAnswer({ quiz: quiz?.id, answers: newTotalAnswers }, () => {
                    setShowQuizCompleteModal(user?.company?.gamification)
                }),
            )
            if (!user?.company?.gamification) {
                navigate('/')
            }
            return
        }

        setActiveQuestion(quiz?.questions[activeQuestionIndex + 1])
        setActiveQuestionIndex(activeQuestionIndex + 1)
    }

    useEffect(() => {
        window.scrollTo({ top: 1, left: 0, behavior: 'smooth' })
        // eslint-disable-next-line
    }, [])

    return (
        <div className="w-full bg-pistachio min-h-screen overflow-y-scroll pb-[250px]">
            {(!quiz.id && loading === false) && <div className="p-4">Quiz not found</div>}

            {quiz.sponsorshipBlock && <div className="mb-3 mobile:hidden"><SponsoredByBlock item={quiz.sponsorshipBlock} /></div>}

            <style>
                {`
                 .radio-style input[type='radio'] {
            outline: none;
            appearance: none;
            width: 24px;
            height: 24px;
            border: 1px solid #417339FF;
            padding: 3px; /* Control the space between border and background */
        }

        .btnd:active,
        .btnd:focus,
        .btnd:focus:active {
            background-image: none;
            outline: 0;
            box-shadow: none;
        }

        .radio-style input[type="radio"]:checked {
            width: 24px;
            height: 24px;
            border-color: #417339FF;
            background: ${(whiteLabeling as any)?.rocket} content-box;
        }
                `}
            </style>

            <div className="flex flex-col h-screen justify-between">
                <div>
                    {quiz.id && (
                        <QuizDetailHeader activeQuestionIndex={activeQuestionIndex}
                            totalQuestionsCount={quiz.questions?.length || 0}
                            onClose={() => setShowQuizQuitOverlayModal(true)} />
                    )}

                    {activeQuestion && (
                        <div className="flex flex-col items-center mt-[54px] mobile:px-[42px] px-[16px]">
                            <div className="max-w-[764px] w-full">
                                <p className="tracking-[-0.01rem] leading-[150%] text-12 text-grey01">
                                    Select {activeQuestion.correctAnswersCountWord}
                                </p>

                                <p className="tracking-[-0.02rem] font mt-1 leading-[132%] text-22 font-medium text-black">
                                    {activeQuestion.question}
                                </p>

                                <div className="w-full mt-8">
                                    {activeQuestion?.answers?.map((item) => (
                                        <Radio
                                            readOnly
                                            containerStyles={{ boxShadow: '2px 2px 0px rgba(0, 0, 0, 0.15)' }}
                                            containerClassName="bg-white p-4 rounded-lg mb-4"
                                            inputClassName="mb-0"
                                            labelClassName="mb-0 tracking-[-0.01rem] leading-[150%] text-grey01 font-medium w-full"
                                            key={item.id}
                                            label={item.answer}
                                            value={item.id}
                                            checked={selectedAnswers.includes(item.id)}
                                            onChange={handleChoiceAnswer}
                                            disabled={showAnswer}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                {quiz.id && (
                    <QuizDetailFooter
                        buttonLoading={loading}
                        question={activeQuestion}
                        answers={selectedAnswers}
                        showAnswer={showAnswer}
                        onSubmit={onSubmit}
                        disabled={activeQuestion?.correctAnswersCount !== selectedAnswers.length} />
                )}
            </div>

            <QuizQuitOverlayModal
                description="If you quit now, you’ll lose your progress and POINTS! You’ll have to restart next time"
                isShown={showQuizQuitOverlayModal}
                onClose={() => navigate('/')}
                onContinue={() => setShowQuizQuitOverlayModal(false)} />

            <QuizCompleteModal
                data={quizAnswerResponse}
                isShown={showQuizCompleteModal}
                onClose={() => {
                    setShowQuizCompleteModal(false)
                    navigate('/')
                }} />
        </div>
    )
}
