import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import LanguageDropdown from '../components/LanguageDropDown'
import SecondButton from '../components/common/SecondButton'
import UnitsDropdown from '../components/UnitsDropDown'
import AccountSidebar from '../components/account/AccountSidebar'

export default function Preferences() {
    const [t] = useTranslation('global')
    const [loading, setLoading] = useState<boolean>(false)
    const [languageShortName, setLanguageShortName] = useState<string>('')
    const [unit, setUnit] = useState<string>('')

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `${window.location.pathname}${window.location.search}`, title: 'Preferences' })
    }, [])

    const handleLanguageChange = (selectedOption: any) => {
        setLanguageShortName(selectedOption?.item?.shortName)
    }

    const handleUnitChange = (selectedOption: any) => {
        setUnit(selectedOption.value)
    }

    const onSubmit = () => {
        setLoading(true)
        if (languageShortName) localStorage.setItem('language', JSON.stringify(languageShortName))
        if (unit) localStorage.setItem('unit', unit)

        setTimeout(() => {
            window.location.reload()
            setLoading(false)
        }, 500)
    }

    return (
        <AccountSidebar>
            <div className="tablet:mb-[120px] mb-[80px] mobile:ml-10 mx-4 mobile:mr-10 mobile:w-[562px]">
                <h1 className="heading-uppercase text-black tracking-tight font-medium tablet:text-36 mobile:text-26 text-24 mb-4">
                    {t('account.Preferences')}
                </h1>

                <h1 className="line text-18 tracking-tight font-bold text-cavolo">
                    {t('account.Language')}
                </h1>

                <div className="mt-[7px]">
                    <LanguageDropdown handleSelectChange={handleLanguageChange} />
                </div>

                <h1 className="line text-18 tracking-tight font-bold text-cavolo mt-4">
                    {t('account.Units')}
                </h1>

                <div className="mt-[7px]">
                    <UnitsDropdown onChange={handleUnitChange} />
                </div>

                <SecondButton
                    loading={loading}
                    onClick={onSubmit}
                    className="w-full mt-4 bg-mint h-[48px] font-semibold rounded-md text-14 text-rocket border-2 border-[#417339] hover:text-spinach hover:ease-in hover:duration-200 hover:bg-[#A7D49D]"
                    text={t('global.save')}
                />
            </div>
        </AccountSidebar>

    )
}
