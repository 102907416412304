import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import Layout from '../../components/layout/Layout'
import { getRecipe } from '../../state/recipe/selectors'
import { fetchRecipeCategoryList, fetchRecipeCollection, fetchRecipeHomePage, fetchRecipeTagsList } from '../../state/recipe/actions'
import { FilterIcon } from '../../components/common/Svg'
import SearchInput from '../../components/common/SearchInput'
import RecipeCard from '../../components/recipe/RecipeCard'
import RecipeCategories from '../../components/common/RecipeCategories'
import RecipeTags from '../../components/common/RecipeTags'
import RecipeWeLoveCard from '../../components/recipe/RecipeWeLoveCard'
import RecentSearches from '../../components/RecentSearches'
import { getPerkLists } from '../../state/perks/selectors'
import { getHomePageLists } from '../../state/home/selectors'
import SponsoredByBlock from '../../components/SponsoredByBlock'

export default function Recipes() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { recipeHomePage, recipeCategories, searchCollection } = useSelector(getRecipe)
    const { recentSearches } = useSelector(getPerkLists)
    const [onFocus, setOnFocus] = useState<boolean>(false)
    const handleFocus = () => setOnFocus(true)
    const [t] = useTranslation('global')

    useEffect(() => {
        setTimeout(() => {
            const firstButton = document.querySelector('#recipes-homepage button') as HTMLButtonElement

            if (firstButton) {
                firstButton.focus()
                firstButton.style.outline = 'none'
            }

            const handleKeyDown = (event: any) => {
                if (event.key === 'Tab') {
                    firstButton.style.removeProperty('outline')
                }
            }

            window.addEventListener('keydown', handleKeyDown)

            return () => {
                window.removeEventListener('keydown', handleKeyDown)
            }
        }, 500)
    }, [recipeHomePage])

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `${window.location.pathname}${window.location.search}`, title: 'Recipes' })
    }, [])

    useEffect(() => {
        if (!recipeHomePage.id) dispatch(fetchRecipeHomePage)
        dispatch(fetchRecipeCollection)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (recipeCategories.length === 0) dispatch(fetchRecipeCategoryList)
        // eslint-disable-next-line
    }, [])

    const data = [
        { id: 1, title: t('lower.all-categories'), content: <RecipeCategories />, loadContent: () => dispatch(fetchRecipeCategoryList) },
        { id: 2, title: t('lower.tags'), content: <RecipeTags />, loadContent: () => dispatch(fetchRecipeTagsList) },
    ]

    const leftSidebarData = {
        data,
        icon: <FilterIcon width={24} height={24} />,
        iconName: t('global.filter'),
        buttonName: t('lower.recipes'),
    }

    const navigateViewAll = (slot: string) => {
        navigate(`/lower/recipes-section/${slot}/view-all`)
    }

    const recipeCollections = [
        { slot: recipeHomePage?.slotOne, key: 'slotOne' },
        { slot: recipeHomePage?.slotTwo, key: 'slotTwo' },
        { slot: recipeHomePage?.slotThree, key: 'slotThree' },
        { slot: recipeHomePage?.slotFour, key: 'slotFour' },
    ].filter((i) => !!i.slot)

    const { sponsorshipBlocks } = useSelector(getHomePageLists)
    const sponsoredBlock = sponsorshipBlocks.find((i) => i.siteSection === 'Recipes')

    return (
        <Layout leftSidebarData={leftSidebarData}>
            <div className="mobile:mx-10 mx-4 mobile:mb-[120px] mb-[80px] mobile:mt-[52px] medium:mt-[52px] mt-[32px]" id="recipes-homepage">
                <h1 className="heading-uppercase medium:block hidden font tracking-tight font-medium mobile:text-32 text-26 tablet:text-4xl text-black mb-6">
                    {t('account.Recipes')}
                </h1>

                <SearchInput
                    smallWidthUntil="medium"
                    action="recipes"
                    placeholder={t('lower.what-would-you-like-to-make')}
                    onFocus={handleFocus} />

                {onFocus && (
                    <RecentSearches data={recentSearches?.recipes} type="recipes" collection={searchCollection} />
                )}

                {sponsoredBlock
                    && (
                        <div className="mobile:hidden w-screen left-[-24px] mt-6 mb-6 relative"><SponsoredByBlock
                            item={sponsoredBlock} />
                        </div>
                    )}

                {recipeHomePage?.recipeWeLove && (
                    <div>
                        <h2 className="tracking-[-.02em] pb-[8px] font-[500px] leading-[132%] text-18 mt-[32px] text-black font heading-font">
                            {t('lower.we-love')}
                        </h2>

                        <RecipeWeLoveCard item={recipeHomePage?.recipeWeLove} />
                    </div>
                )}

                {recipeCollections?.map((collectionItem) => (
                    <div className="mt-[60px]" key={collectionItem.key}>
                        <div className="flex items-end justify-between">
                            <span className="heading-font font-medium tracking-tight font text-black text-18">
                                {collectionItem?.slot?.name}
                            </span>

                            <div onClick={() => navigateViewAll(collectionItem.key)}
                                className="font-semibold cursor-pointer not-heading-font tracking-tight underline underline-offset-2 text-rocket text-14">
                                {t('global.view-all')}
                            </div>
                        </div>

                        <div
                            className="grid md:grid-cols-3 grid-cols-2 aspect-w-16 aspect-h-9 gap-[24px] laptop:gap-8 mt-3 ">
                            {collectionItem?.slot?.recipe?.slice(0, 6).map((recipeItem) => (
                                <div key={recipeItem.id}
                                    className="hover:bg-gray-200 hover:ease-in hover:duration-200 hover:drop-shadow-[0.3 alpha] hover:delay-200 ">
                                    <RecipeCard item={recipeItem} type="recipe" />
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </Layout>
    )
}
