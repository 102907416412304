import React, { useCallback, useEffect, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import { getUser } from '../../state/user/selectors'
import { fetchHowToEarnPoints } from '../../state/user/actions'
import HowToEarnPointsCard from '../../components/HowToEarnPointsCard'
import SponsoredByBlock from '../../components/SponsoredByBlock'
import { getHomePageLists } from '../../state/home/selectors'
import AccountSidebar from '../../components/account/AccountSidebar'

export default function HowToEarnPoints() {
    const dispatch = useDispatch()
    const { howToEarnPoints, loading } = useSelector(getUser)
    const [t] = useTranslation('global')

    const onRestartLoad = useCallback(() => {
        dispatch(fetchHowToEarnPoints)
        // eslint-disable-next-line
    }, [])

    useLayoutEffect(() => {
        onRestartLoad()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        // eslint-disable-next-line
    }, [])

    const { sponsorshipBlocks } = useSelector(getHomePageLists)
    const sponsoredBlock = sponsorshipBlocks.find((i) => i.siteSection === 'Earn points')

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `${window.location.pathname}${window.location.search}`, title: 'How to earn points' })
    }, [])

    return (
        <AccountSidebar>
            {sponsoredBlock && <div className="mobile:hidden w-screen left-[-24px]"><SponsoredByBlock item={sponsoredBlock} /></div>}

            <div className="tablet:mb-[120px] mb-[80px] mobile:ml-10 mx-4 tablet:mr-[92px] medium:mr-[88px] mobile:mr-10 laptop:w-[760px]">
                <h1 className="heading-uppercase medium:block hidden font font-medium mobile:text-32 text-26 tablet:text-4xl text-black">
                    {t('me.how-to-earn-points')}
                </h1>

                <p className="not-heading-font relative font-medium font text-grey01 max-mobile:text-14 text-18 mt-[20px] mb-[30px] w-11/12">
                    {t('me.there-are-lots-of-ways-to-earn-points-throughout-the-platform')}
                </p>

                <div className="grid lg-gap-8 md:grid-cols-2 grid-cols-2 lg:gap-8 gap-4 mt-3">
                    {(!loading ? howToEarnPoints : [{}, {}, {}, {}, {}, {}])?.map((item, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <div key={index}>
                            <HowToEarnPointsCard item={item} />
                        </div>
                    ))}
                </div>
            </div>
        </AccountSidebar>
    )
}
