import React, { useContext, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import { ArrowTravel, DismissIcon, QuestionIcon } from '../../components/common/Svg'
import { ITrackTravelCarType } from '../../types/data'
import QuizQuitOverlayModal from '../../components/quiz/QuizQuitOverlayModal'
import { commuteStartEndList, legTransportList, travelSelector } from '../../state/travel/selectors'
import { getUser, getWhiteLabelingList } from '../../state/user/selectors'
import Layout from '../../components/layout/Layout'
import { Context } from '../../components/common/BaseContext'
import { COLOR_CONST, TRAVEL_TEXT_SPLITTER } from '../../constants'
import { getHomePageLists } from '../../state/home/selectors'
import SponsoredByBlock from '../../components/SponsoredByBlock'
import { replaceAll } from '../../utils/helpers'
import { legTransport, removeTravelLeg } from '../../state/travel/actions'
import Button from '../../components/common/Button'
import { Colors } from '../../themes'

interface IProps {
    disableSelecting?: boolean
}

export default function TrackTravelModality({ disableSelecting }: IProps) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const location = useLocation()
    const { transportList, legStartEnd } = useSelector(travelSelector)
    const { isEmbedded } = useContext(Context)
    const { screenWidth } = useContext(Context)
    const { user } = useSelector(getUser)
    const { transportModes, loading } = useSelector(travelSelector)
    const { commuteStartEnd } = useSelector(commuteStartEndList)
    const { legTransports } = useSelector(legTransportList)
    const { whiteLabeling } = useSelector(getWhiteLabelingList)
    const { navbarHeight } = useContext(Context)

    const [typeData, setTypeData] = useState(transportList)
    const [showQuizQuitOverlayModal, setShowQuizQuitOverlayModal] = useState<boolean>(false)
    const [onContinue, setOnContinue] = useState(false)
    const [startLeg, setStartLeg] = useState('')
    const [endLeg, setEndLeg] = useState('')
    const [t] = useTranslation('global')
    const [showTooltipFirst, setShowTooltipFirst] = useState<boolean>(false)

    const byCar = typeData?.find((item) => item?.name?.includes('Car'))
    const byTaxi = typeData?.find((item) => item?.name?.includes('Taxi'))
    const choosedCar = typeData.filter((item) => item.selected)
    const allMode = typeData.filter((item) => item.selected)
    const goingBackCommute = location?.state?.goingBackCommute
    const edit = location?.state?.edit
    const impact = location?.state?.impact
    const byCarBack = location?.state?.byCarBack
    const allModeBack = location?.state?.allModeBack
    const travelName = location?.state?.travelName
    const storedDataString = localStorage.getItem(goingBackCommute ? 'returnTravelData' : 'travelData')
    // @ts-ignore
    const storedData = JSON.parse(storedDataString)
    const travelDataString = localStorage.getItem('travelData')

    // @ts-ignore
    const travelData = JSON.parse(travelDataString)
    const leg = travelData?.legs?.find((item: any) => item.id === edit)

    const storedDataEditString = localStorage.getItem('travelDataEdit')
    // @ts-ignore
    const storedDataEdit = JSON.parse(storedDataEditString)
    const outboundData = JSON.parse(localStorage.getItem('travelData') || '{}')
    const places = JSON.parse(localStorage.getItem('places') || '{}')

    const legStart = (storedDataEdit?.startPlaceName || startLeg)?.split(',')[0]
    const legEnd = (storedDataEdit?.endPlaceName || endLeg || '?').split(',')[0]

    const startLocationName = storedData?.isOverallStart || storedData?.createEndLeg ? '?' : travelName || legStart
    const endLocationName = storedData?.isOverallEnd || storedData?.createStartLeg ? ' ' : legEnd

    function findPlaceType(place: any, placeNames: any) {
        return placeNames[place] || ''
    }

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `${window.location.pathname}${window.location.search}`, title: 'Track travel modality' })
    }, [])

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [pathname])

    const legEndType = findPlaceType(legEnd, places)
    const legStartType = findPlaceType(legStart, places)
    const game = outboundData.gameData

    const containerRef = useRef<HTMLDivElement>(null)
    const [itemsPerRow, setItemsPerRow] = useState(4) // Default value

    useEffect(() => {
        const updateItemsPerRow = () => {
            if (containerRef.current) {
                const containerWidth = containerRef.current.offsetWidth
                const itemWidth = 103 + 10
                setItemsPerRow(Math.floor(containerWidth / itemWidth))
            }
        }

        updateItemsPerRow()
        window.addEventListener('resize', updateItemsPerRow)

        return () => {
            window.removeEventListener('resize', updateItemsPerRow)
        }
    }, [])

    const onChangeValueType = (item: ITrackTravelCarType) => {
        const newData = typeData.map((newItem) => (newItem.id === item.id ? { ...newItem, selected: true } : { ...newItem, selected: false }))
        setTypeData(newData)
        setOnContinue(true)
    }

    useEffect(() => {
        if (edit && !storedDataEdit.hideSelectedValues) {
            const newData = typeData?.map((newItem) => (newItem?.name === leg?.transportName
                ? { ...newItem, selected: !newItem.selected }
                : { ...newItem, selected: false }))
            // @ts-ignore
            setTypeData(newData)
        }
        // eslint-disable-next-line
    }, [edit])

    useEffect(() => {
        const legTransportData = {
            leg: storedDataEdit?.backendLegId || legTransports?.id,
            commute: outboundData?.backendCommuteId || commuteStartEnd?.id,
            isGoingBack: goingBackCommute,
            transport: choosedCar[0]?.id,
            position: storedDataEdit?.position || storedData.createLegPosition || ((storedData.legs?.length || 0) + 1),
        }

        if (onContinue) {
            if (byCar?.selected || byTaxi?.selected) {
                dispatch(legTransport(legTransportData, () => {
                    setOnContinue(false)
                    navigate('/log/log-travel-car-details', { state: { impact, byCar, choosedCar: choosedCar[0], goingBackCommute, edit, prevUrl: location?.state?.prevUrl } })
                }))
                return
            }

            dispatch(legTransport(legTransportData, () => {
                navigate('/log/track-travel-leg-start-end', { state: { impact, choosedCar: choosedCar[0], goingBackCommute, edit, allMode, prevUrl: location?.state?.prevUrl } })
            }))
        }
        // eslint-disable-next-line
    }, [onContinue, edit])

    const onSubmit = () => {
        const legTransportData = {
            leg: storedDataEdit?.backendLegId || legTransports?.id,
            commute: outboundData?.backendCommuteId || commuteStartEnd?.id,
            isGoingBack: goingBackCommute,
            transport: choosedCar[0]?.id,
            position: storedDataEdit?.position || storedData.createLegPosition || ((storedData.legs?.length || 0) + 1),
        }

        if (byCar?.selected || byTaxi?.selected) {
            dispatch(legTransport(legTransportData, () => {
                setOnContinue(false)
                navigate('/log/log-travel-car-details', { state: { impact, byCar, choosedCar: choosedCar[0], goingBackCommute, edit, prevUrl: location?.state?.prevUrl } })
            }))
            return
        }

        dispatch(legTransport(legTransportData, () => {
            navigate('/log/track-travel-leg-start-end', { state: { impact, choosedCar: choosedCar[0], goingBackCommute, edit, allMode, prevUrl: location?.state?.prevUrl } })
        }))
    }

    useEffect(() => {
        if (edit && !storedDataEdit.hideSelectedValues) {
            const newData = typeData.map((newItem) => (newItem.id === storedDataEdit?.transportId ? { ...newItem, selected: !newItem.selected } : { ...newItem, selected: false }))
            setTypeData(newData)
        }

        if (byCarBack) {
            const newData = typeData.map((newItem) => (newItem.id === byCarBack?.id ? { ...newItem, selected: !newItem.selected } : { ...newItem, selected: false }))
            setTypeData(newData)
        }

        if (allModeBack) {
            const newData = typeData.map((newItem) => (newItem.id === allModeBack[0]?.id ? { ...newItem, selected: !newItem.selected } : { ...newItem, selected: false }))
            setTypeData(newData)
        }
        // eslint-disable-next-line
    }, [edit, storedDataEdit?.transportId, byCarBack, allModeBack])

    function onBack() {
        if (storedDataEdit) {
            // if (storedDataEdit.legName)
            localStorage.removeItem('travelDataEdit')

            // if (storedDataEdit.legName > 1) {
            //     const newLegs = storedData?.legs.filter((i: ILeg) => i.id !== storedDataEdit.id)
            //     localStorage.setItem(goingBackCommute ? 'returnTravelData' : 'travelData', JSON.stringify({ ...storedData, legs: newLegs }))
            navigate('/log/track-travel-overview', { state: { impact } })
            // }
            //
            // if (storedDataEdit.legName === 1) {
            //     localStorage.removeItem(goingBackCommute ? 'returnTravelData' : 'travelData')
            //     navigate('/log')
            // }
            return
        }

        const legs = storedData?.legs

        if (legs?.length > 0) {
            const item = legs[legs.length - 1]
            localStorage.setItem('travelDataEdit', JSON.stringify({ ...item, legName: legs.length }))

            if (legTransports?.id) {
                dispatch(removeTravelLeg(legTransports?.id))
            }

            navigate('/log/track-travel-leg-start-end', { state: { choosedCar: item.choosedCar, fuel: item.transportFuelType, sizeCar: item.transportSize, edit: true, goingBackCommute } })
            // navigate('/log/track-travel-overview', { state: { impact } })
            return
        }

        if (goingBackCommute) {
            navigate('/log/track-travel-overview')
            return
        }

        navigate('/log/track-travel-start-end')
    }

    useEffect(() => {
        if (travelData.legStartEnd) {
            setStartLeg(travelData.legStartEnd.startPlaceName)
            setEndLeg(travelData.legStartEnd.endPlaceName)
            return
        }

        if ((legStartEnd?.nextLegDefaults || commuteStartEnd?.legDefaultsData)?.legStart) {
            const splitData = (legStartEnd.nextLegDefaults || commuteStartEnd.legDefaultsData).legStart.split(TRAVEL_TEXT_SPLITTER)
            setStartLeg(splitData[0])
        }

        if ((legStartEnd?.nextLegDefaults || commuteStartEnd?.legDefaultsData)?.legEnd) {
            const splitData = (legStartEnd.nextLegDefaults || commuteStartEnd.legDefaultsData).legEnd.split(TRAVEL_TEXT_SPLITTER)
            setEndLeg(splitData[0])
        }

        // eslint-disable-next-line
    }, [])

    const { sponsorshipBlocks } = useSelector(getHomePageLists)
    const sponsoredBlock = sponsorshipBlocks.find((i) => i.siteSection === 'Log')

    const disabledStyles = {
        backgroundColor: Colors.grey03,
        borderColor: Colors.grey03,
        color: Colors.grey02,
        boxShadow: '2px 2px 0px rgba(0, 0, 0, 0.15)',
        transitionDuration: '0.1s',
    }

    return (
        <Layout showFooter={false} spaceOnBottom={!isEmbedded}>
            <div className="w-full h-screen">
                <div style={{ marginTop: navbarHeight, left: '50%', transform: 'translateX(-50%)' }}
                    className="fixed top-0 left-0 w-full z-10 bg-white max-w-[1440px]">
                    {sponsoredBlock && <div className="mobile:hidden"><SponsoredByBlock item={sponsoredBlock} /></div>}

                    <div className="flex justify-between items-center h-[53px] pr-2 mobile:mx-[42px] mx-4">
                        <div onClick={onBack} className="flex justify-end cursor-pointer">
                            <ArrowTravel width={22} height={22} />
                        </div>

                        <div onClick={() => setShowQuizQuitOverlayModal(true)}
                            className="flex justify-end cursor-pointer">
                            <DismissIcon width={18} height={18} />
                        </div>
                    </div>
                </div>

                <div style={{ marginTop: screenWidth > 768 ? navbarHeight : !sponsoredBlock ? navbarHeight : 125 }}
                    className="h-screen flex flex-col items-center mobile:px-[42px] px-4">
                    <div className="max-w-[600px] w-full pb-[300px]">
                        <h1 className="heading-uppercase text-22 mt-2 text-cavolo tracking-tight font-semibold">
                            {t('log.journey-leg-details')}
                        </h1>

                        <p className="text-14 text-grey01 font-normal mt-[7px]">
                            {storedData?.isOverallStart ? '?' : legStart} {legStartType} {t('global.to')} {legEnd} {legEndType}
                        </p>

                        <div className="flex items-center justify-between mt-4 mb-[10px]">
                            <h1 className="text-16 tracking-tight font-medium text-cavolo not-heading-font">
                                {t(`log.${game?.isFuture ? 'FUTURE|' : ''}how-did-you-travel`)} {startLocationName ? `${t('log.from')} ${startLocationName}` : null} {legStartType} {endLocationName ? `${t('global.to')} ${endLocationName}` : null} {legEndType}?
                                {/* { t('log.how-did-you-travel-from')} {travelName || storedDataEdit?.startPlaceName?.split(',')[0] || (startLeg?.split(',')[0] || '?')} {t('global.to')} {storedDataEdit?.endPlaceName?.split(',')[0] || endLeg?.split(',')[0]}? */}
                            </h1>

                            <div
                                className="flex items-end flex-col-reverse justify-end mx-[5px] mt-[5px] cursor-pointer"
                                onClick={() => setShowTooltipFirst(!showTooltipFirst)}>
                                <QuestionIcon />

                                {showTooltipFirst && (
                                    <div
                                        className={cn('mt-[30px] bg-lime text-cavolo tracking-[-.01em] leading-[150%] px-[19px] py-[16px] text-14 font-normal rounded-lg absolute mobile:w-[320px] z-10', css(screenWidth <= 768 ? styles.textActive : styles.textDefault))}>
                                        {t(`log.${game?.isFuture ? 'FUTURE|' : ''}if-you-travelled-by-multiple-modes-of-transport,-select-the-one-by-which-you-travelled-the-longest-distance`)}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div ref={containerRef} className="flex flex-wrap max-xs-max:justify-center">
                            {typeData.map((item, index) => {
                                const transportMode = transportModes?.find((i) => i?.name === item?.name?.toLowerCase())
                                const isLastInRow = (index + 1) % itemsPerRow === 0

                                return (
                                    <button
                                        key={item?.id}
                                        disabled={loading || disableSelecting}
                                        onClick={() => onChangeValueType(item)}
                                        style={{ opacity: loading || disableSelecting ? 0.5 : 1 }}
                                        className={`w-[103px] h-[103px] flex items-end justify-center rounded-[10px] border-solid border-2 ${item?.selected ? 'border-cavolo' : 'border-mint'} mt-[15px] ${item?.selected ? 'bg-rocket' : 'bg-pistachio'} cursor-pointer ${item.name === 'e-bike/e-scooter' ? 'pb-[3px]' : 'pb-[15px]'} ${!isLastInRow ? 'mr-4' : ''}`}>
                                        <span className="flex flex-col items-center">
                                            {/* eslint-disable-next-line react/no-danger */}
                                            <div
                                                dangerouslySetInnerHTML={{ __html: replaceAll(transportMode?.icon || '', COLOR_CONST, item?.selected ? whiteLabeling?.white : whiteLabeling?.cavolo) }} />

                                            <span
                                                className={`mt-2 text-14 font-semibold ${item?.selected ? 'text-white' : 'text-cavolo'} not-heading-font`}>
                                                {item.name}
                                            </span>
                                        </span>
                                    </button>
                                )
                            })}
                        </div>
                    </div>

                    <div className={`fixed bottom-0 ${isEmbedded ? 'h-[120px]' : 'h-[112px]'} bg-pistachio w-full duration-100`}
                        style={{ boxShadow: '0px -2px 5px rgba(0, 0, 0, 0.15)' }}>
                        <div
                            className="mx-auto flex max-mobile:flex-col max-w-[848px] relative h-full px-[16px] mobile:px-[42px]">
                            <div className="flex-1 mt-7 mobile:mr-[40px] mr-[16px]" />

                            <div className={`${isEmbedded ? 'mb-4' : ''} mobile:ml-8 mobile:w-[180px] relative`}>
                                <Button
                                    loading={loading}
                                    onClick={onSubmit}
                                    disabled={!onContinue}
                                    style={!onContinue ? disabledStyles : undefined}
                                    className="absolute bottom-8 duration-100 not-heading-font"
                                    text={t('global.continue')} />
                            </div>
                        </div>
                    </div>
                </div>

                <QuizQuitOverlayModal
                    description={user?.company?.gamification ? t('log.quit-description-is-switched-on-gamification') : t('log.quit-description-is-switched-off-gamification')}
                    isShown={showQuizQuitOverlayModal}
                    onClose={() => (isEmbedded ? navigate('/') : navigate(location?.state?.prevUrl || '/'))}
                    onContinue={() => setShowQuizQuitOverlayModal(false)} />
            </div>
        </Layout>
    )
}

const styles = StyleSheet.create({
    textActive: {
        right: 40,
        left: 40,
        filter: 'drop-shadow(2px 2px 0px rgba(0, 0, 0, 0.15))',
    },
    textDefault: {
        boxShadow: '2px 2px 0px rgba(0, 0, 0, 0.15)',
    },
})
