import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import ReactGA from 'react-ga4'
import AccountSidebar from '../components/account/AccountSidebar'

export default function TermsAndConditions() {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [])

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `${window.location.pathname}${window.location.search}`, title: 'Terms and conditions' })
    }, [])

    return (
        <AccountSidebar>
            <div className="tablet:mb-[120px] mb-[80px] mobile:ml-10 mx-4 tablet:mr-[92px] medium:mr-[88px] mobile:mr-10 laptop:w-[760px]">
                <h6 className="font-normal text-12 leading-[23.76px] tracking-[-.01em] text-grey01">
                    Effective Date: 01 Sept 2022
                </h6>

                <h1 className="font font-medium text-black mobile:text-[32px] tablet:text-[36px] laptop:text-[36px] text-26 tracking-[-.02em] leading-[126%] mb-8 ">Terms
                    of Service: Lowr App
                </h1>

                <section className="mb-9">
                    <div className="font-normal text-16 tracking-[-.01em] text-[#5B5B5B] leading-[150%]">
                        <h2 className="mb-4 font font-medium text-[22px] leading-[23.76px] tracking-[-.02em] text-black">
                            Terms of website use
                        </h2>

                        <p className="mb-4">
                            By accessing or using this website (app.lowr.co) (this "Website"), you agree to
                            be bound by the following Terms and Conditions and our Privacy Policy.
                            If you do not agree to be bound by these Terms and Conditions and our Privacy Policy,
                            please do not use or access this Website.
                        </p>

                        <h2 className="mb-4 font font-medium text-[22px] leading-[23.76px] tracking-[-.02em] text-black">
                            About Lowr
                        </h2>

                        <p className="mb-4">
                            This Website is operated by The Green Shoot Institute, a limited company registered
                            in England and Wales under company number 12891189 with its registered office at C/O
                            Sustainable Ventures County Hall, 3rd Floor, Westminster Bridge Road, London, England,
                            SE1 7PB. For the purposes of these Terms and Conditions, "Lowr" shall mean the
                            engagement platform created and delivered by The Green Shoot Institute Ltd.
                        </p>

                        <h2 className="mb-4 font font-medium text-[22px] leading-[23.76px] tracking-[-.02em] text-black">
                            DISCLAIMER- Important Information
                        </h2>

                        <p className="mb-4">
                            The content on this Platform is for entertainment and informational purposes only.
                            Any information shared, whether as part of our blogs, recipes or nutritional plans
                            is general information and not bespoke advice. You should always seek the advice of
                            a trained medical professional before starting on a new diet or fitness regime.
                        </p>

                        <h2 className="mb-4 font font-medium text-[22px] leading-[23.76px] tracking-[-.02em] text-black">
                            Changes to these Terms of Use and the Website
                        </h2>

                        <p className="mb-4">
                            Except where prohibited by applicable law, we reserve the right, in our sole discretion,
                            to change these Terms of Use at any time by posting a new version to the Website.
                            Any and all such modifications are effective immediately upon posting and apply to
                            all access to and continued use of the Website. It is your obligation to monitor
                            the Website for any such changes. Your continued access to or use of the Website
                            after any changes to these Terms of Use indicates your acceptance of such changes.
                            It is your responsibility to review these Terms of Use regularly in order to be aware
                            of any such modifications.
                        </p>

                        <p className="mb-4">
                            This Website is made available "as is" and we reserve the right to change,
                            withdraw or terminate the information, materials, and Content on the Website
                            at any time, without notice. We will not be liable if, for any reason, all or
                            any part of the Website is restricted to users or unavailable at any time or
                            for any period.
                        </p>

                        <p className="mb-4">
                            If you choose, or you are provided with, a user identification code, password
                            or any other piece of information as part of this Website's security procedures,
                            you must treat such information as confidential, and you must not disclose it to
                            any third party. Lowr has the right to disable any user identification code or password,
                            whether chosen by you or allocated by us, at any time, if in Lowr’s opinion you have
                            failed to comply with any of the provisions of these Terms and Conditions or our
                            <NavLink to="/privacy-policy" className="inline-block">
                                <h5 className="underline font-semibold pointer text-14 text-rocket">
                                    Privacy Policy.
                                </h5>
                            </NavLink>
                            You are responsible for making all arrangements necessary for you to have access to
                            this Website. You are also responsible for ensuring that all persons who access
                            this Website through your internet connection are aware of and comply with these
                            Terms and Conditions and our Privacy Policy.
                        </p>
                    </div>
                </section>

                <section className="mb-9">
                    <h2 className="mb-4 font font-medium text-[22px] leading-[23.76px] tracking-[-.02em] text-black">
                        Intellectual property rights
                    </h2>

                    <div className="font-normal text-16 leading-[150%] tracking-[-.01em] text-[#5B5B5B]">
                        <p className="mb-4">
                            All right, title and interest, including intellectual property rights, in the
                            Website and all other materials provided by us hereunder, and any updates, adaptation,
                            translation, customisation or derivative works thereof, will remain the sole property
                            of Lowr (or our third-party suppliers, if applicable).
                        </p>

                        <p className="mb-4">
                            The Website and all materials provided by us hereunder
                            are made available or licensed and not “sold” to you.
                        </p>

                        <p className="mb-4">
                            The Website and all other materials provided by us hereunder, including Content we make
                            available through or in the Website, are protected by copyright in the United Kingdom.
                            You
                            are prohibited from modifying, copying, reproducing, publishing, posting, transmitting,
                            distributing, creating derivative works from, decompiling, transferring or selling any
                            part
                            of the Website, the Content, or other materials provided by us hereunder, or sharing or
                            granting access in any of the foregoing to any third party for any purpose.
                        </p>

                        <p className="mb-4">
                            Lowr is the trading name of The Green Shoot Institute Ltd. Any trademarks, graphics or
                            logos
                            appearing on the Website or in the Content are the exclusive property of The Green Shoot
                            Institute Ltd (or its third-party suppliers) and may not be used in any manner without
                            our
                            express written consent.
                        </p>

                        <p className="mb-4">
                            No Unlawful or Prohibited Use You will not use the Website in violation of these Terms
                            of
                            Use or of any applicable law. You will not, without our prior written permission, use
                            the
                            Website for any purpose other than to access partners' offers and to learn more about
                            Lowr
                            (including its products and services) that we make available through the Website.
                            Without
                            limiting the generality of the foregoing, you will not (and will not attempt to)
                            directly or
                            indirectly:
                        </p>

                        <p className="mb-4 font font-medium text-16 leading-[23.76px] tracking-[-.02em] text-[#5B5B5B]">
                            <ol className="list-decimal ml-10">
                                <li className="mb-1">
                                    disable, overly burden, impair, or otherwise interfere with servers or networks
                                    connected to the Website (e.g., a denial of service attack);
                                </li>

                                <li className="mb-1">
                                    attempt to gain unauthorized access to the Website, or bypass any measures we
                                    may
                                    use to prevent or restrict access to the Website;
                                </li>

                                <li className="mb-1">
                                    send, upload, collect, transmit, store, use, post, publish, or otherwise
                                    communicate
                                    on the Website any data, information, pictures, videos, audio or other materials
                                    or
                                    content that: (i) contains any computer viruses, worms, malicious code, or any
                                    software intended to damage or alter a computer system or data; (ii) you do not
                                    have
                                    the lawful right to send, upload, collect, transmit, store, use, post, publish,
                                    or
                                    otherwise communicate; (iii) is false, intentionally misleading, or impersonates
                                    any
                                    other person, including reviews that are not representative of your experience;
                                    (iv)
                                    is libellous, slanderous, defamatory, bullying, harassing, abusive, threatening,
                                    vulgar, obscene, or offensive, or that contains pornography, nudity, or graphic
                                    or
                                    gratuitous violence, or that promotes violence, racism, discrimination, bigotry,
                                    hatred, or physical harm of any kind against any group or individual; (v) is
                                    harmful
                                    to minors in any way or targeted at minors; (vi) infringes, violates or
                                    otherwise
                                    misappropriates the intellectual property or other rights of any third party
                                    (including any moral right, privacy right or right of publicity); or (vii)
                                    encourages any conduct that may violate any applicable laws or would give rise
                                    to
                                    civil or criminal liability;
                                </li>

                                <li className="mb-1">
                                    publish, market, advertise or in any way distribute the content;
                                </li>

                                <li className="mb-1">
                                    share, transfer or otherwise provide access to an account designated for you to
                                    another person;
                                </li>

                                <li className="mb-1">
                                    use any data mining, robots, or similar data gathering or extraction methods, or
                                    copy, modify, reverse engineer, reverse assemble, disassemble, or decompile the
                                    Website or any part thereof or otherwise attempt to discover any source code;
                                </li>

                                <li className="mb-1">
                                    use the Website for the purpose of building a similar or competitive product or
                                    service; or
                                </li>

                                <li className="mb-1">
                                    authorize, permit, enable, induce or encourage any third party to do the above.
                                </li>
                            </ol>
                        </p>
                    </div>
                </section>

                <section>
                    <h2 className="mb-4 font font-medium text-[22px] leading-[23.76px] tracking-[-.02em] text-black">
                        Lowr’ liability
                    </h2>

                    <h2 className="mb-4 font font-medium text-[22px] leading-[23.76px] tracking-[-.02em] text-black">
                        Loss or Damage
                    </h2>
                    <div className="font-normal text-16 leading-[150%] tracking-[-.01em] text-[#5B5B5B]">
                        <p className="mb-4">
                            Notwithstanding any of these terms, we do not intend to exclude or limit our
                            liability to you where it would be unlawful to do so. We do not exclude or limit
                            liability for
                            death or personal injury caused by our negligence or the negligence of our employees,
                            agents or
                            subcontractors and for fraud or fraudulent misrepresentation.
                        </p>

                        <p className="mb-4">
                            We have no liability to You for any loss of profit, loss of business, business
                            interruption or
                            loss of business opportunity. To the maximum extent permissible at law, our aggregate
                            liability
                            to you in connection with these terms howsoever arising in contract, negligence or
                            otherwise, is
                            limited to £50.
                        </p>

                        <h2 className="mb-4 font font-medium text-[22px] leading-[23.76px] tracking-[-.02em] text-black">
                            Disclaimers
                        </h2>

                        <p className="mb-4">
                            The information on this website is intended for entertainment and information purposes
                            only and
                            does not constitute medical advice. The information on this Site is provided without any
                            representations or warranties, express or implied.
                        </p>

                        <p className="mb-4">
                            You must obtain professional or specialist advice before taking, or refraining from
                            taking, any
                            action on the basis of the content on our Site.
                        </p>

                        <p className="mb-4">
                            You should contact your GP for further information prior to beginning any new physical
                            activity
                            or a significant increase in activity. If you experience chest pain at any point whilst
                            exercising you should stop immediately and seek urgent medical assistance.
                        </p>

                        <p className="mb-4">
                            If you are new to exercise, pregnant or have given birth within the last six months, or
                            have
                            recently been injured, you should consult a medical professional before taking part in
                            any
                            physical exercise and follow their advice.
                        </p>

                        <p className="mb-4">
                            Although reasonable efforts are made to update the information on this Site, we make no
                            representations, warranties or guarantees, whether express or implied, that the content
                            on our
                            Site is accurate, complete or up to date.
                        </p>

                        <p className="mb-4">
                            The content on this Site does not constitute dietary, fitness or personal training
                            advice.
                            Should you require advice under any of these heads then you should contact a
                            professional
                            directly.
                        </p>

                        <p className="mb-4">
                            Viewing the information on this Site does not constitute a contractual relationship
                            between you
                            and us.
                        </p>

                        <h2 className="mb-4 font font-medium text-[22px] leading-[23.76px] tracking-[-.02em] text-black">
                            Nothing in this disclaimer will:
                        </h2>

                        <p className="mb-4">
                            (a) limit or exclude any liability for death or personal injury resulting from
                            negligence;
                        </p>

                        <p className="mb-4">
                            (b) limit or exclude any liability for fraud or fraudulent misrepresentation;
                        </p>

                        <p className="mb-4">
                            (c) limit any liabilities in any way that is not permitted under applicable law; or
                        </p>

                        <p className="mb-4">
                            (d) exclude any liabilities that may not be excluded under applicable law.
                        </p>

                        <p className="mb-4">
                            This Site may contain links to other sites. These sites are not under our control and we
                            cannot
                            be responsible for any actions or events arising from you following any links from this
                            Site.
                            Links are provided for informational purposes only and should not be interpreted as
                            approval or
                            endorsement by us.
                        </p>

                        <h2 className="mb-4 font font-medium text-[22px] leading-[23.76px] tracking-[-.02em] text-black">
                            Communications Not Confidential
                        </h2>

                        <p className="mb-4">
                            We do not guarantee the confidentiality of any communications made by you through the
                            Website.
                            We do not guarantee the security of data transmitted over the Internet or public
                            networks in
                            connection with your use of the Website.
                        </p>

                        <h2 className="mb-4 font font-medium text-[22px] leading-[23.76px] tracking-[-.02em] text-black">
                            No Reliance
                        </h2>

                        <p className="mb-4">
                            The content on our Website is provided for general information purposes only. It is not
                            intended
                            to amount to advice on which you should rely. You must obtain more specific or
                            professional
                            advice before taking, or refraining from, any action or inaction on the basis of the
                            content on
                            our site. Although we make reasonable efforts to update the information on our Website,
                            we make
                            no representations, warranties, or guarantees, whether express or implied, that the
                            content on
                            our Website is accurate, complete, or up to date. Your use of the Website is at your own
                            risk
                            and neither us nor our parent, subsidiaries, affiliates, and their respective directors,
                            officers, employees, agents, service providers, contractors, licensors, licensees,
                            suppliers, or
                            successors have any responsibility or liability whatsoever for your use of this Website.
                        </p>

                        <h2 className="mb-4 font font-medium text-[22px] leading-[23.76px] tracking-[-.02em] text-black">
                            Third Party Promotions, Content, Websites or Services
                        </h2>

                        <p className="mb-4">
                            The Website may provide you access to third party promotions and offers through links,
                            third
                            party content, websites, or services. Likewise, we may allow you to access the Website
                            from
                            third party systems. We do not endorse any third party content, websites, services, or
                            systems,
                            or guarantee their quality, accuracy, reliability, completeness, currency, timeliness,
                            non-infringement, merchantability, or fitness for any purpose. Third party content,
                            websites,
                            services, or systems (including any offers or promotions) are not under our control, and
                            if you
                            choose to access any such content, websites, or services, or to access the Website from
                            such
                            systems, you do so entirely at your own risk. Lowr is not responsible for any products
                            or
                            services offered by third parties. You acknowledge that you may be required to accept
                            terms of
                            use applicable to third party content, websites, services, or systems and agree to
                            accept and
                            comply with any such terms of use.
                        </p>

                        <p className="mb-4">
                            Any comments on our blog or social media channels are not endorsed or verified by us. We
                            are not
                            responsible for the comments on our Site made by third parties. Should we become aware
                            of
                            comments which are likely to be distressing we will remove them from our Site. Removal
                            of
                            comments does not indicate that any compensation will be awarded, it will not, comments
                            are
                            removed to ensure enjoyment for Site users.
                        </p>

                        <h2 className="mb-4 font font-medium text-[22px] leading-[23.76px] tracking-[-.02em] text-black">
                            Cookies
                        </h2>

                        <p className="mb-4">
                            Lowr may use cookies, web beacons, tracking pixels, and other tracking technologies when
                            you
                            visit our website, including any other media form, media channel, mobile website, or
                            mobile
                            application related or connected thereto (collectively, the “Site”) to help customise
                            the Site
                            and improve your experience.
                        </p>

                        <p className="mb-4">
                            We reserve the right to make changes to this Cookie Policy at any time and for any
                            reason. We
                            will alert you about any changes by updating the “Last Updated” date of this Cookie
                            Policy. Any
                            changes or modifications will be effective immediately upon posting the updated Cookie
                            Policy on
                            the Site, and you waive the right to receive specific notice of each such change or
                            modification.
                        </p>

                        <p className="mb-4">
                            You are encouraged to periodically review this Cookie Policy to stay informed of
                            updates. You
                            will be deemed to have been made aware of, will be subject to, and will be deemed to
                            have
                            accepted the changes in any revised Cookie Policy by your continued use of the Site
                            after the
                            date such revised Cookie Policy is posted.
                        </p>

                        <p className="mb-4">
                            A “cookie” is a small text file that is placed on your computer by websites that you
                            visit. We
                            use cookies on the Site to, among other things, keep track of services you have used,
                            record
                            registration information, record your user preferences, keep you logged into the Site,
                            and track
                            the pages you visit. Cookies help us understand how the Site is being used and improve
                            your user
                            experience. Cookies do not typically include identifying personal information, but
                            cookies may
                            also be linked to personal information which is stored about you.
                        </p>

                        <p className="mb-4">
                            We use cookies to retain your user preferences, store information from elements of our
                            Sites
                            such as shopping carts, and to provide anonymised tracking data to third party
                            applications
                            including Google Analytics, Facebook Pixel, and Instagram Ads. These cookies are likely
                            to be
                            analytical/performance cookies or targeting cookies. We have no control over third party
                            cookies.
                        </p>

                        <p className="mb-4">
                            In general, cookies should make your browsing experience better. You may prefer to
                            disable
                            cookies for this Site which you can do by disabling cookies in your browser. We suggest
                            following the steps via the ‘Help’ tool of your browser. Please note that if you change
                            your
                            browser settings to block all cookies (including essential cookies) you may not be able
                            to
                            access some or all of our Site.
                        </p>

                        <h2 className="mb-4 font font-medium text-[22px] leading-[23.76px] tracking-[-.02em] text-black">
                            Except for essential cookies, all cookies expire within a reasonable period of time.
                        </h2>

                        <p className="mb-4">
                            We also use tracking pixels in our marketing emails. These pixels tell us if, and when,
                            you
                            opened an email from us. We use this information to better understand how customers
                            engage with
                            our content and to analyse the effectiveness of our marketing campaigns.
                        </p>

                        <p className="mb-4">
                            You can disable tracking pixels by changing your email settings to block the loading or
                            display
                            of external images.
                        </p>

                        <h2 className="mb-4 font font-medium text-[22px] leading-[23.76px] tracking-[-.02em] text-black">
                            Malicious Code and Security
                        </h2>

                        <p className="mb-4">
                            The downloading and viewing of Content is done at your own risk. We do not guarantee or
                            warrant
                            that the Website is compatible with your computer system or that the Website, or any
                            links from
                            the Website, will be free of viruses, worms, trojan horses or disabling devices or other
                            code
                            that manifests contaminating or destructive properties. You are responsible for
                            implementing
                            safeguards to protect the security and integrity of your computer system, and you are
                            responsible for the entire cost of any service, repairs or connections of and to your
                            computer
                            system that may be necessary as a result of your use of the Website. The downloading and
                            viewing
                            of Content is done at your own risk. We do not guarantee or warrant that the Website is
                            compatible with your computer system or that the Website, or any links from the Website,
                            will be
                            free of viruses, worms, trojan horses or disabling devices or other code that manifests
                            contaminating or destructive properties. You are responsible for implementing safeguards
                            to
                            protect the security and integrity of your computer system, and you are responsible for
                            the
                            entire cost of any service, repairs or connections of and to your computer system that
                            may be
                            necessary as a result of your use of the Website.
                        </p>

                        <p className="mb-4">
                            The Website is intended to provide a forum to help facilitate Challenges. Lowr does not
                            verify
                            nor attempt to verify the status of any Challenges nor whether any Challenges are
                            actually
                            carried out by any user. Where the Website indicates the amount of carbon dioxide saved
                            in
                            respect of a challenge, such indication is provided as a guide and estimate only an not
                            obliged
                            to ensure that such savings are made by the users of the Website or otherwise.
                        </p>

                        <h2 className="mb-4 font font-medium text-[22px] leading-[23.76px] tracking-[-.02em] text-black">
                            Your access to this Website
                        </h2>

                        <p className="mb-4">
                            Lowr processes information about you in accordance with our Privacy Policy. By accessing
                            or
                            using this Website, you consent to such processing and you warrant that all data
                            provided by you
                            is accurate.
                        </p>

                        <h2 className="mb-4 font font-medium text-[22px] leading-[23.76px] tracking-[-.02em] text-black">
                            Uploading material to this Website
                        </h2>

                        <p className="mb-4">
                            The Website may contain Interactive Functions allowing uploading material to or through
                            the
                            Website. Whenever you upload material to this Website, or make contact with other users
                            of the
                            site, you must comply with the following content standards.
                        </p>

                        <p className="mb-4">
                            (a) Anything you upload must be: accurate; genuinely held (for example if it is an
                            opinion); and
                            comply with applicable law in the UK and in any country from which the material is
                            uploaded.
                        </p>

                        <p className="mb-4">
                            (b) Material you upload must not: contain unsolicited or unauthorised advertising or
                            promotional
                            material or any other form of similar solicitation (spam); contain any material which is
                            defamatory of any person; contain any material which is obscene, offensive, hateful or
                            inflammatory; contain sexually explicit material; promote violence; promote
                            discrimination based
                            on race, sex, religion, nationality, disability, sexual orientation or age; infringe any
                            copyright, trade mark or other intellectual property right of any other person; be
                            likely to
                            deceive any person; be made in breach of any legal duty owed to a third party, such as a
                            contractual duty of confidence; promote any illegal activity; be threatening, abuse or
                            invade
                            another's privacy, or cause annoyance, inconvenience or needless anxiety; be likely to
                            harass,
                            upset, embarrass, alarm or annoy any other person; be likely to impersonate any person,
                            or to
                            misrepresent your identity or affiliation with any person; give the impression that they
                            emanate
                            from Lowr; advocate, promote or assist any unlawful act.
                        </p>

                        <p className="mb-4">
                            You warrant that any such material does comply with those standards, and you indemnify
                            us for
                            any breach of that warranty.
                        </p>

                        <p className="mb-4">
                            Any material you upload to this Website will be considered non-confidential and
                            non-proprietary,
                            and Lowr has the right to use, copy, distribute and disclose to third parties any such
                            material
                            for any purpose. Lowr also has the right to disclose your identity to any third party
                            who is
                            claiming that any material posted or uploaded by you to this Website constitutes a
                            violation of
                            their intellectual property rights, their right to privacy or any other right.
                        </p>

                        <p className="mb-4">
                            Lowr will not be responsible, or liable to any third party, for the content or accuracy
                            of any
                            materials posted by you or any other user of this Website.
                        </p>

                        <p className="mb-4">
                            We reserve the right to remove, edit, limit, or block access to the content or materials
                            at any
                            time, and we have no obligation to display or review your content.
                        </p>

                        <h2 className="mb-4 font font-medium text-[22px] leading-[23.76px] tracking-[-.02em] text-black">
                            Prohibited uses
                        </h2>

                        <p className="mb-4">
                            You may use the Website only for lawful purposes. You may not use the Website: in any
                            way that
                            breaches any applicable local, national or international law or regulations; in any way
                            that is
                            unlawful or fraudulent, or has any unlawful or fraudulent purpose or effect; for the
                            purpose of
                            harming or attempting to harm minors in any way; to send, knowingly receive upload,
                            download,
                            use or re-use any material which does not comply with our content standards (set out
                            above); to
                            transmit or procure the sending of any unsolicited or unauthorised advertising or
                            promotional
                            material or any other form of similar solicitation (spam).
                        </p>

                        <h2 className="mb-4 font font-medium text-[22px] leading-[23.76px] tracking-[-.02em] text-black">
                            Miscellaneous
                        </h2>

                        <p className="mb-4">
                            Whilst using this Site you may provide personal information about yourself including
                            your name
                            and email address. When providing these details, you agree to provide accurate and up to
                            date
                            information.
                        </p>

                        <p className="mb-4">
                            For the avoidance of doubt these Website Terms of Use stand as terms only, there is no
                            intention
                            to create a contract.
                        </p>

                        <h2 className="mb-4 font font-medium text-[22px] leading-[23.76px] tracking-[-.02em] text-black">
                            Jurisdiction and applicable law
                        </h2>

                        <p className="mb-4">
                            These Terms and Conditions and any dispute or claim arising from, or related to, a visit
                            to this
                            Website, shall be governed by and construed in accordance with the law of England and
                            Wales.
                        </p>

                        <p className="mb-4">
                            The English courts shall have exclusive jurisdiction over any such dispute or claim
                        </p>

                        <h2 className="mb-4 font font-medium text-[22px] leading-[23.76px] tracking-[-.02em] text-black">
                            Contact
                        </h2>

                        <p className="mb-4">
                            If you have any questions or comments regarding these Terms of Use, please contact us at
                            <a href="mailto:hello@lowr.co" className="inline-block">
                                <h5 className="underline font-semibold pointer text-14 text-rocket">
                                    hello@lowr.co
                                </h5>
                            </a>
                        </p>
                    </div>
                </section>
            </div>
        </AccountSidebar>
    )
}
