import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { DefaultHeartIcon, TravelTreeBigEllipse } from '../common/Svg'
import GoogleAutoComplete from '../GoogleAutoComplete'
import { travelSelector } from '../../state/travel/selectors'
import { ILegs } from '../../types/data'
import Button from '../common/Button'
import LegDetailsCollapsedLeg from './LegDetailsCollapsedLeg'
import { getSavedPlacesList, getUser } from '../../state/user/selectors'

interface IProps {
    onClose: () => void;
    setPlacesData: (value: any) => void
    placesData: any
    onSave: () => void
    editItem?: ILegs
    setSelectedTransports: (value: any) => void
    selectedTransports: any
    setDeleteLegIds: (value: any) => void
    deleteLegIds: number[]
}

export default function LegDetails({ onClose, setPlacesData, placesData, onSave, editItem, selectedTransports, setSelectedTransports, deleteLegIds, setDeleteLegIds }: IProps) {
    const [t] = useTranslation('global')
    const { loading } = useSelector(travelSelector)
    const { user } = useSelector(getUser)
    const { savedPlaces } = useSelector(getSavedPlacesList)
    const firstItem: any = editItem?.combinedLegs ? editItem?.combinedLegs[0] : {}
    const lastItem: any = editItem?.combinedLegs ? editItem?.combinedLegs[(editItem?.combinedLegs?.length || 1) - 1] : {}

    const onChangePlace = (value: any, leg: ILegs, side: 'start' | 'end', additionalData?: ILegs) => {
        const oldPlaceData = placesData[leg.backendLegId || 0] || {}

        setPlacesData({
            ...placesData,
            [leg.backendLegId || 0]: side === 'start' ? {
                ...oldPlaceData,
                startPlaceId: value.name === leg?.startPlaceName ? undefined : value.placeId,
                startPlaceLat: value.name === leg?.startPlaceName ? undefined : value.lat,
                startPlaceLon: value.name === leg?.startPlaceName ? undefined : value.lon,
                startPlaceName: value.name === leg?.startPlaceName ? undefined : value.name,
            } : {
                ...oldPlaceData,
                endPlaceId: value.name === leg?.endPlaceName ? undefined : value.placeId,
                endPlaceLat: value.name === leg?.endPlaceName ? undefined : value.lat,
                endPlaceLon: value.name === leg?.endPlaceName ? undefined : value.lon,
                endPlaceName: value.name === leg?.endPlaceName ? undefined : value.name,
            },

            ...additionalData,
        })
    }

    return (
        <div>
            {isEmpty(savedPlaces) && user?.company?.showSavedPlaces ? (
                <div className="flex items-center mb-2.5">
                    <DefaultHeartIcon />

                    <h1 className="ml-2.5 text-12 text-grey01">
                        {t('savedPlaces.tap-the-heart-to-save-a-place-for-future-use')}
                    </h1>
                </div>
            ) : null}

            <div className="flex items-center">
                <TravelTreeBigEllipse red={!editItem} />

                <div className="pl-4 w-full">
                    <GoogleAutoComplete dontEmpty placeholder={t('log.start-typing')}
                        defaultValueLeg={placesData[firstItem?.backendLegId || 0]?.startPlaceName || firstItem?.startPlaceName}
                        setPlace={(value) => onChangePlace(value, firstItem, 'start')} />
                </div>
            </div>

            <div className="w-full ml-2.5 pr-4 mb-[-10px] mt-[-10px]"
                // style={{ borderLeft: '10px dashed #FF6337' }}
            >
                {(editItem ? editItem?.combinedLegs?.filter((i) => !deleteLegIds.includes(i?.backendLegId || 0)) : [undefined])?.map((collapsedLegItem, index, items) => (
                    <LegDetailsCollapsedLeg
                        setDeleteLegIds={setDeleteLegIds}
                        deleteLegIds={deleteLegIds}
                        onChangePlace={onChangePlace}
                        selectedTransports={selectedTransports}
                        placesData={placesData}
                        setSelectedTransports={setSelectedTransports}
                        item={collapsedLegItem}
                        index={index}
                        items={items} />
                ))}
            </div>

            <div className="flex items-center mb-5">
                <TravelTreeBigEllipse red={!editItem} />

                <div className="pl-4 w-full">
                    <GoogleAutoComplete dontEmpty placeholder={t('log.start-typing')} defaultValueLeg={placesData[lastItem?.backendLegId || 0]?.endPlaceName || lastItem?.endPlaceName} setPlace={(value) => onChangePlace(value, lastItem, 'end')} />
                </div>
            </div>

            <div className="flex items-center justify-between">
                <button
                    onClick={onClose}
                    className="w-[48%] h-[48px] border border-grey01 rounded-md font-semibold not-heading-font leading-[150%] tracking-[-0.02rem] text-14 text-grey01">
                    {t('global.cancel')}
                </button>

                <Button
                    text={t('global.save')}
                    onClick={onSave}
                    loading={loading}
                    defaultStyles={false}
                    className="w-[48%] h-[48px] bg-rocket rounded-md font-semibold not-heading-font leading-[150%] tracking-[-0.02rem] text-14 text-white" />
            </div>
        </div>
    )
}
