import React, { useContext, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import useOutsideClick from '../../hooks/useOutsideAlerter'
import Navbar from '../layout/Navbar'
import { More,
    MoreIcon,
    Pencil,
    UserDefaultIcon } from '../common/Svg'
import Footer from '../layout/Footer'
import { getUser } from '../../state/user/selectors'
import { getMonths } from '../../utils/date'
import { fetchUser, fetchUserAvatarUpdate } from '../../state/user/actions'
import { Context } from '../common/BaseContext'
import UploadImageModal from './UploadImageModal'
import Skeleton from '../common/Skeleton'
import SustainabilityMenu from '../layout/SustainabilityMenu'
import AccountMenuContent from '../AccountMenuContent'

interface IProps {
    children?: React.ReactNode
    prizeDraw?: boolean
}

export default function AccountSidebar({ children, prizeDraw }: IProps): JSX.Element {
    const dispatch = useDispatch()
    const { user } = useSelector(getUser)
    const { navbarHeight } = useContext(Context)
    const [open, setOpen] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const modalRef = useRef(null)
    const { screenWidth } = useContext(Context)
    const [t] = useTranslation('global')

    // const data = [
    //     {
    //         id: 1,
    //         title: 'ACCOUNT SETTINGS',
    //         icon: '',
    //         items: [
    //             { id: 1, name: 'My info', href: '/me/account-settings', icon: '' },
    //             { id: 2, name: `${t('navbar.My')} ${user?.company?.organisationalUnitName || 'club'}`, href: '/me/organisation-unit', icon: '' },
    //             { id: 3, name: 'Saved places', href: '/me/saved-places', icon: '' },
    //             ...(!user?.company?.enableSchedule ? [] : [{ id: 5, name: 'Schedule', href: '/me/schedule', icon: '' }]),
    //             { id: 6, name: 'Preferences', href: '/me/preferences', icon: '' },
    //             { id: 7, name: 'Account overview', href: '/account-overview', icon: '' },
    //             {
    //                 id: 8,
    //                 name: 'Sign out',
    //                 icon:
    // <div className="mr-2 flex justify-center items-center w-[24px] h-[24px]">
    //     <SignOutIcon width={19} height={20} color={whiteLabeling.raspberry} />
    // </div>,
    //             },
    //
    //         ],
    //     },
    //     {
    //         id: 2,
    //         title: 'BOOKMARKS',
    //         icon: '',
    //         items: [
    //             { id: 9,
    //                 name: 'Swaps',
    //                 href: '/lower/swaps/swaps-bookmark',
    //                 icon:
    // <div className="mr-2 flex justify-center w-6 h-6">
    //     <PerkIcon width={22} height={21} />
    // </div> },
    //             {
    //                 id: 10,
    //                 name: 'Articles',
    //                 href: '/learn/articles/article-bookmark',
    //                 icon:
    // <div className="mr-2 flex justify-center w-6 h-6">
    //     <ArticleIcon width={22} height={22} />
    // </div>,
    //             },
    //             {
    //                 id: 11,
    //                 name: 'Recipes',
    //                 href: '/lower/recipes/recipe-bookmark',
    //                 icon:
    // <div className="mr-2 flex justify-center w-6 h-6">
    //     <RecipeIcon width={24} height={24} />
    // </div>,
    //             },
    //             {
    //                 id: 12,
    //                 name: 'Pledges',
    //                 href: '/lower/pledges/pledges-list',
    //                 icon:
    // <div className="mr-2 flex justify-center w-6 h-6">
    //     <PledgeIcon width={24} height={24} />
    // </div>,
    //             },
    //         ],
    //     },
    //     {
    //         id: 3,
    //         title: 'SUPPORT',
    //         icon: '',
    //         items: [
    //             { id: 13, name: 'How to earn points', href: '/me/earn-points', icon: '' },
    //             { id: 14, name: 'Contact us', href: 'https://www.lowr.co/contact-us', icon: '' },
    //             { id: 15, name: 'FAQs', href: '/faqs', icon: '' },
    //             ...(!user?.company?.gamification
    //                 ? []
    //                 : [{ id: 14, name: 'Prize draw', href: '/prize-draw', icon: '' }]
    //             ),
    //         ],
    //     },
    //     {
    //         id: 4,
    //         title: 'Legal',
    //         icon: '',
    //         items: [
    //             { id: 16, name: 'Terms of service', href: '/terms-of-services', icon: '' },
    //             { id: 17, name: 'Privacy policy', href: '/privacy-policy', icon: '' },
    //         ],
    //     },
    // ]

    const handleCloseModal = () => {
        setOpen(false)
    }

    useOutsideClick(modalRef, handleCloseModal)

    const handleOpenModal = () => {
        setOpen(true)
    }

    const onSubmit = () => {
        dispatch(fetchUser)
    }

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return
        }

        const file = e.target.files[0]
        setLoading(true)
        setShowModal(false)
        dispatch(fetchUserAvatarUpdate({ uploadedImage: file }))
        dispatch(fetchUser)
        setLoading(false)
    }

    // const renderSidebarItems = () => data.map((item) => (
    //     <div key={item.id}>
    //         <h1 className="text-rocket font-medium text-12 tracking-[0.06em] mt-[30px]">
    //             {t(`account.${item.title}`)}
    //         </h1>
    //         {item.items.map((i) => (
    //             <div
    //                 key={i.id}
    //                 onClick={() => {
    //                     if (i?.name === 'Sign out') {
    //                         onLogout()
    //                     }
    //                     if (i?.href?.startsWith('https')) {
    //                         // @ts-ignore
    //                         window.open(i.href, '_blank').focus()
    //                         return
    //                     }
    //                     navigate(i?.href || '/')
    //                 }}
    //                 className="h-8"
    //             >
    //                 <h1
    //                     className={`border-avocado ${i.name === 'Sign out' ? 'mt-[29px] text-raspberry' : 'mt-2 text-black'} w-full text-lg border-b cursor-pointer font tracking-tight flex items-center ${
    //                         pathName.startsWith(i.name.toLowerCase()) && 'font-bold'
    //                     }`}
    //                 >
    //                     {pathName.startsWith(i?.href || 'home') && (
    //                         <div className="mr-2">
    //                             <RightArrow width={13} height={8} />
    //                         </div>
    //                     )}
    //                     {i.icon}
    //                     {i.name.startsWith('My') ? i.name : t(`account.${i.name}`)}
    //                 </h1>
    //             </div>
    //         ))}
    //     </div>
    // ))

    return (
        <div className="flex flex-col overflow-y-hidden h-screen justify-between">
            <style>{'html, body {height: 100%; overflow: hidden;}'}</style>
            <Navbar spaceOnBottom={false} />

            <div className="flex overflow-y-hidden">
                {!prizeDraw && (
                    <div style={{ paddingTop: navbarHeight + 32 }}
                        className="hidden px-2 mobile:block tablet:hidden h-full top-0 bg-pistachio w-60 mobile:pt-[52px]">
                        <div onClick={handleOpenModal}
                            className="inline-flex p-2 text-sm text-gray-500 rounded-lg focus:outline-none">
                            <div className="flex flex-col items-center">
                                <MoreIcon width={24} height={24} />

                                <span className="font-semibold tracking-tight text-rocket text-12">
                                    {t('account.more')}
                                </span>
                            </div>
                        </div>
                    </div>
                )}

                <div ref={modalRef} style={{ paddingTop: navbarHeight }}
                    className={`overflow-y-scroll top-0 left-0 w-[311px] bg-pistachio pt-[50px] pl-6 text-white fixed h-full z-40 ease-in-out duration-300 ${open ? 'translate-x-0' : '-translate-x-full'}`}>
                    <div className="h-full mt-4 mr-10 py-4">
                        <div className="flex items-center">
                            {loading ? (
                                <Skeleton className="w-[60px] h-[60px] object-cover rounded-full" />
                            ) : (
                                screenWidth <= 500 ? (
                                    <Formik onSubmit={onSubmit} initialValues={{ uploadedImage: '' }}>
                                        <Form>
                                            <div className="flex flex-col items-center">
                                                <label htmlFor="file-upload"
                                                    className="flex items-center justify-center cursor-pointer">
                                                    <div className="flex justify-end cursor-pointer relative">
                                                        {!user.uploadedImage && !user.socialImage ? (
                                                            <div className="w-[60px] h-[60px] object-cover">
                                                                <UserDefaultIcon width={60} height={60} />
                                                            </div>
                                                        ) : user.uploadedImage ? (
                                                            <div className="w-[60px] h-[60px]">
                                                                <img
                                                                    className="rounded-full w-full h-full object-cover"
                                                                    src={user.uploadedImage}
                                                                    alt=""
                                                                />
                                                            </div>
                                                        ) : (
                                                            <div className="w-[60px] h-[60px]">
                                                                <img
                                                                    className="rounded-full object-cover w-full h-full"
                                                                    src={user.socialImage}
                                                                    alt=""
                                                                />
                                                            </div>
                                                        )}

                                                        <div className="absolute">
                                                            <Pencil width={16} height={16} />
                                                        </div>
                                                    </div>
                                                </label>

                                                <input
                                                    name="image"
                                                    id="file-upload"
                                                    type="file"
                                                    className="hidden"
                                                    onChange={handleFileChange} />
                                            </div>
                                        </Form>
                                    </Formik>
                                ) : (
                                    <div onClick={() => setShowModal(true)}
                                        className="flex justify-end cursor-pointer relative">
                                        {!user.uploadedImage && !user.socialImage ? (
                                            <div className="w-[60px] h-[60px] object-cover">
                                                <UserDefaultIcon width={60} height={60} />
                                            </div>
                                        ) : user.uploadedImage ? (
                                            <div className="w-[60px] h-[60px]">
                                                <img
                                                    className="rounded-full w-full h-full object-cover"
                                                    src={user.uploadedImage}
                                                    alt=""
                                                />
                                            </div>
                                        ) : (
                                            <div className="w-[60px] h-[60px]">
                                                <img
                                                    className="rounded-full object-cover w-full h-full"
                                                    src={user.socialImage}
                                                    alt=""
                                                />
                                            </div>
                                        )}

                                        <div className="absolute">
                                            <Pencil width={16} height={16} />
                                        </div>
                                    </div>
                                )
                            )}

                            <div className="ml-4">
                                <span className="font-medium text-24 tracking-[-0.02rem] text-black font">
                                    {user.firstName} {user.lastName}
                                </span>

                                <h1 className="text-grey01 text-14 font-normal">
                                    {t('account.joined')} {getMonths(user.dateJoined)}
                                </h1>
                            </div>
                        </div>

                        <div className="mt-[30px]">
                            <AccountMenuContent right />
                        </div>

                        <div className="pb-[80px]" />

                        {/* <div onClick={onLogout} className="flex flex-col mt-[37px] pb-[80px] pl-[2px] cursor-pointer"> */}
                        {/*    <div className="flex"> */}
                        {/*        <div className="mr-2 flex justify-center items-center w-[24px] h-[24px]"> */}
                        {/*            <SignOutIcon width={19} height={20} color={whiteLabeling.raspberry} /> */}
                        {/*        </div> */}

                        {/*        <h1 className="w-full text-raspberry text-18 font tracking-tight flex items-center"> */}
                        {/*            {t('global.sign-out')} */}
                        {/*        </h1> */}
                        {/*    </div> */}

                        {/*    <div className="w-full h-[1px] border-b border-avocado" /> */}
                        {/* </div> */}
                    </div>
                </div>

                <div style={{ paddingTop: navbarHeight + 35 }}
                    className="hidden overflow-y-scroll tablet:block bg-pistachio pl-10 laptop:pl-10 large-laptop:pl-80 laptop:w-[480px] tablet:w-[390px] laptop:grow h-full">
                    <div className="h-full laptop:mr-10 mr-6 py-4">
                        <div className="flex items-center">
                            {loading ? (
                                <Skeleton className="w-[60px] h-[60px] object-cover rounded-full" />
                            ) : (
                                <div onClick={() => setShowModal(true)}
                                    className="flex justify-end cursor-pointer relative">
                                    {!user.uploadedImage && !user.socialImage ? (
                                        <div className="w-[60px] h-[60px] object-cover">
                                            <UserDefaultIcon width={60} height={60} />
                                        </div>
                                    ) : user.uploadedImage ? (
                                        <div className="w-[60px] h-[60px]">
                                            <img
                                                className="rounded-full w-full h-full object-cover"
                                                src={user.uploadedImage}
                                                alt=""
                                            />
                                        </div>
                                    ) : (
                                        <div className="w-[60px] h-[60px]">
                                            <img
                                                className="rounded-full object-cover w-full h-full"
                                                src={user.socialImage}
                                                alt=""
                                            />
                                        </div>
                                    )}

                                    <div className="absolute">
                                        <Pencil width={16} height={16} />
                                    </div>
                                </div>
                            )}

                            <div className="ml-4">
                                <span className="font-medium text-24 tracking-[-0.02rem] text-black font">
                                    {user.firstName} {user.lastName}
                                </span>

                                <h1 className="text-grey01 text-14 font-normal">
                                    {t('account.joined')} {getMonths(user.dateJoined)}
                                </h1>
                            </div>
                        </div>

                        <div className="mt-[30px]">
                            <AccountMenuContent right />
                        </div>

                        <div className="pb-[80px]" />

                        {/* <div onClick={onLogout} className="flex flex-col mt-[37px] pb-[80px] pl-[2px]"> */}
                        {/*    <div className="flex"> */}
                        {/*        <div className="mr-2 flex justify-center items-center w-[24px] h-[24px]"> */}
                        {/*            <SignOutIcon width={19} height={20} /> */}
                        {/*        </div> */}

                        {/*        <h1 onClick={() => navigate('/')} className="w-full text-black text-18 font tracking-tight flex items-center"> */}
                        {/*            {t('global.sign-out')} */}
                        {/*        </h1> */}
                        {/*    </div> */}

                        {/*    <div style={{ borderBottomColor: whiteLabeling?.avocado }} className="w-full h-[1px] border-b" /> */}
                        {/* </div> */}
                    </div>
                </div>

                <div
                    className="overflow-y-scroll flex flex-col justify-between w-full large-laptop:w-4/6 mobile:pt-[52px] pt-[32px] min-h-screen" style={{ paddingTop: navbarHeight }}>
                    <div className="mobile:mt-[52px] mt-[32px] flex-1">
                        {children}
                    </div>

                    <Footer />
                    {user?.company?.menuPosition === 'Bottom' && <SustainabilityMenu isFixed />}
                </div>
            </div>

            {!prizeDraw && (
                <div onClick={handleOpenModal} className="flex justify-center items-center">
                    <div
                        className="fixed bottom-[100px] mobile:hidden cursor-pointer flex justify-center items-center w-[100px] h-[48px] bg-rocket rounded-[30px]"
                        style={{ boxShadow: ('2px 3px 0px rgba(0, 0, 0, 0.2') }}>
                        <div className="mr-2">
                            <More width={18} height={18} />
                        </div>

                        <h1 className="text-16 leading-[17.6px] text-white font-semibold font">
                            {t('account.more')}
                        </h1>
                    </div>
                </div>
            )}

            <UploadImageModal showModal={showModal} setShowModal={setShowModal} setLoading={setLoading} />
        </div>
    )
}
