import React, { useCallback, useContext, useEffect, useLayoutEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import DatePicker, { registerLocale } from 'react-datepicker'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import Button from '../../components/common/Button'
import 'react-datepicker/dist/react-datepicker.css'
import QuizQuitOverlayModal from '../../components/quiz/QuizQuitOverlayModal'
import { Colors } from '../../themes'
import { getUser } from '../../state/user/selectors'
import Dropdown from '../../components/travel/DropDown'
import { fetchGame, fetchTransport, fetchTransportModes, fetchTravelContentBlock } from '../../state/travel/actions'
import Layout from '../../components/layout/Layout'
import { Context } from '../../components/common/BaseContext'
import SponsoredByBlock from '../../components/SponsoredByBlock'
import { getHomePageLists } from '../../state/home/selectors'
import { travelSelector } from '../../state/travel/selectors'
import { clearTravelData } from '../../utils/travel'
import { getDatesLocalization } from '../../utils/date'
import ArticleModal from '../../components/article/ArticleModal'
import ContentBlockMini from '../../components/ContentBlockMini'
import { fetchSavedPlaces } from '../../state/user/actions'

export default function TrackTravelFan() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const location = useLocation()
    const { user } = useSelector(getUser)
    const { isEmbedded, showArticleModal, setShowArticleModal } = useContext(Context)
    const { sponsorshipBlocks } = useSelector(getHomePageLists)
    const [startDate, setStartDate] = useState<Date | null>(null)
    const [buttonShow, setButtonShow] = useState<boolean>(false)
    const [gameDate, setGameDate] = useState<Date | string | any>('')
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [showQuizQuitOverlayModal, setShowQuizQuitOverlayModal] = useState<boolean>(false)
    const { travelContentBlock } = useSelector(travelSelector)
    const [t] = useTranslation('global')

    const languageDataString = localStorage.getItem('language')
    const languageData = languageDataString ? JSON.parse(languageDataString) : 'en'

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [pathname])

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `${window.location.pathname}${window.location.search}`, title: 'Track travel fan' })
    }, [])

    useEffect(() => {
        clearTravelData(dispatch)

        // eslint-disable-next-line
    }, [])

    const onRestartLoad = useCallback(() => {
        dispatch(fetchGame())
        dispatch(fetchTransport())
        dispatch(fetchTransportModes())
        dispatch(fetchTravelContentBlock())
        dispatch(fetchSavedPlaces)
        // eslint-disable-next-line
    }, [])

    useLayoutEffect(() => {
        onRestartLoad()
        // eslint-disable-next-line
    }, [])

    const onSubmit = () => {
        const today = new Date()

        localStorage.setItem('travelData', JSON.stringify({
            date: startDate || today,
            gameDate: gameDate?.value,
            gameId: gameDate?.id,
            legs: [],
            gameData: gameDate.item,
        }))

        if (user.company?.travelLogByFavourite === 'Hidden' && user?.company?.travelLogByRecent === 'Hidden') {
            navigate('/log/track-travel-start-end', { state: { prevUrl: location?.state?.prevUrl } })

            return
        }

        navigate('/log', { state: { prevUrl: location?.state?.prevUrl } })
    }

    useEffect(() => {
        if (gameDate) {
            onSubmit()
        }
        // eslint-disable-next-line
    }, [gameDate])

    const handleClick = async (e: any) => {
        if (languageData !== 'en') {
            const es = await import(`date-fns/locale/${languageData}`)
            registerLocale(languageData, es.default)
        }
        e.preventDefault()
        setIsOpen(!isOpen)
    }

    const handleChange = (e: any) => {
        setIsOpen(!isOpen)
        setStartDate(e)
        setButtonShow(true)
    }

    const disabledStyles = {
        backgroundColor: Colors.grey03,
        borderColor: Colors.grey03,
        color: Colors.grey02,
        boxShadow: '2px 2px 0px rgba(0, 0, 0, 0.15)',
        transitionDuration: '0.1s',
    }

    const handleSelectChange = (selectedOption: any) => {
        setGameDate(selectedOption)
    }

    const sponsoredBlock = sponsorshipBlocks.find((i) => i.siteSection === 'Log')

    const [formattedDate, setFormattedDate] = useState<string | undefined>(undefined)

    useEffect(() => {
        const fetchData = async () => {
            if (startDate) {
                const formatted = await getDatesLocalization(startDate, languageData)
                setFormattedDate(formatted)
            }
        }
        fetchData()
        // eslint-disable-next-line
    }, [startDate])

    return (
        <Layout showFooter={false} spaceOnBottom={!isEmbedded} maxWidth={false} travel>
            <div className="w-full">
                {sponsoredBlock && <div className="mb-3 mobile:hidden"><SponsoredByBlock item={sponsoredBlock} /></div>}

                <div className="flex flex-col items-center mobile:pt-[54px] pt-5 mobile:px-[42px] px-[16px] h-screen">
                    <div className="max-w-[600px] w-full pb-[300px]">
                        {travelContentBlock.title && (
                            <ContentBlockMini />
                        )}

                        <h1 className="heading-uppercase text-22 tracking-tight font-semibold text-cavolo mt-[63px]">
                            {t('log.log-a-journey')}
                        </h1>

                        {user?.company?.isTravelTypeEvent ? (
                            <h1 className="line text-16 tracking-tight font-medium not-heading-font text-cavolo mt-[24px]">
                                {t('log.which-game-would-you-like-to-log-travel-for').replace('[game]', user?.company.travelTrackingType === 'standard' ? 'game' : user.company.travelEventName)}
                            </h1>
                        ) : (
                            <h1 className="text-16 tracking-tight font-medium not-heading-font text-cavolo mt-[27px]">
                                {t('log.when-did-you-travel?')}
                            </h1>
                        )}

                        {startDate && (
                            <h1 className="my-[10px] text-22 text-black font-bold not-heading-font">
                                {formattedDate}
                            </h1>
                        )}

                        {user?.company?.isTravelTypeEvent ? (
                            <div className="mt-[7px]">
                                <Dropdown handleSelectChange={handleSelectChange} />
                            </div>
                        ) : (
                            <div>
                                <Button
                                    type="button"
                                    className="bottom-8 duration-100 mt-[7px]"
                                    text={t('log.today')}
                                    onClick={onSubmit} />

                                <Button
                                    onClick={handleClick}
                                    type="button"
                                    className="bottom-8 bg-pistachio duration-100 border-spinach text-cavolo"
                                    text={t('log.select-another-date')} />
                            </div>
                        )}

                        {isOpen && (
                            <div className="flex justify-center mt-4">
                                <DatePicker
                                    selected={startDate}
                                    onChange={handleChange}
                                    inline
                                    dateFormat="dd/mm/yyyy"
                                    locale={languageData}
                                />
                            </div>
                        )}
                    </div>
                </div>

                {(buttonShow || user?.company?.isTravelTypeEvent) && (
                    <div className={`fixed bottom-0 ${isEmbedded ? 'h-[120px]' : 'h-[112px]'} bg-pistachio w-full duration-100`} style={{ boxShadow: '0px -2px 5px rgba(0, 0, 0, 0.15)' }}>
                        <div className="mx-auto flex max-mobile:flex-col max-w-[848px] relative h-full px-[16px] mobile:px-[42px]">
                            <div className="flex-1 mt-7 mobile:mr-[40px] mr-[16px]" />

                            <div className={`${isEmbedded ? 'mb-4' : ''} mobile:ml-8 mobile:w-[180px] relative`}>
                                {user?.company?.isTravelTypeEvent ? (
                                    <Button
                                        onClick={onSubmit}
                                        disabled={!gameDate}
                                        style={!gameDate ? disabledStyles : undefined}
                                        className="absolute bottom-8 duration-100 not-heading-font"
                                        text={t('global.continue')} />
                                ) : (
                                    <Button
                                        onClick={onSubmit}
                                        disabled={!user?.company?.isTravelTypeEvent && startDate === null}
                                        style={!user?.company?.isTravelTypeEvent && startDate === null ? disabledStyles : undefined}
                                        className="absolute bottom-8 duration-100 not-heading-font"
                                        text={t('global.continue')} />
                                )}
                            </div>
                        </div>
                    </div>
                )}

                <QuizQuitOverlayModal
                    description={user?.company?.gamification ? t('log.quit-description-is-switched-on-gamification') : t('log.quit-description-is-switched-off-gamification')}
                    isShown={showQuizQuitOverlayModal}
                    onClose={() => navigate(location?.state.prevUrl || '/')}
                    onContinue={() => setShowQuizQuitOverlayModal(false)} />

                {showArticleModal && showArticleModal === travelContentBlock?.overlayArticle ? (
                    <ArticleModal setShowModal={setShowArticleModal} articleId={travelContentBlock?.overlayArticle} />
                ) : undefined}
            </div>
        </Layout>
    )
}
