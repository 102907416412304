import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import AccountSidebar from '../../components/account/AccountSidebar'
import { LeftArrow } from '../../components/common/Svg'
import { IUpdateUserPassword } from '../../types/data'
import { fetchUpdateUserPassword, fetchUser } from '../../state/user/actions'
import { required } from '../../utils/validators'
import InputPassword from '../../components/common/InputPassword'
import SecondButton from '../../components/common/SecondButton'
import ServerError from '../../components/common/ServerError'

export default function ChangePassword() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [t] = useTranslation('global')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `${window.location.pathname}${window.location.search}`, title: 'Change password' })
    }, [])

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        // eslint-disable-next-line
    }, [])

    const onSubmit = async (data: IUpdateUserPassword) => {
        setLoading(true)
        await dispatch(fetchUpdateUserPassword(data, () => {
            navigate('/me/account-settings')
        }, setError))
        await dispatch(fetchUser)
        setLoading(false)
    }

    const initialValues: IUpdateUserPassword = {
        oldPassword: '',
        newPassword: '',
        newPassword2: '',
    }

    return (
        <AccountSidebar>
            <div className="tablet:mb-[310px] mb-[80px] tablet:h-screen mobile:ml-10 mx-4 tablet:mr-[92px] medium:mr-[88px] mobile:mr-10 laptop:w-[760px]">
                <div className="flex items-center">
                    <span onClick={() => navigate('/account-overview')} className="text-rocket tracking-tight italic font font-normal cursor-pointer underline underline-offset-2 text-14">
                        {t('account.account')}
                    </span>

                    <div className="mx-[4px]">
                        <LeftArrow height={12} width={12} />
                    </div>

                    <span onClick={() => navigate('/me/account-settings')} className="text-rocket tracking-tight italic font font-normal cursor-pointer underline underline-offset-2 text-14">
                        {t('account.My Info')}
                    </span>
                </div>

                <h1 className="mobile:text-36 text-26 font tracking-tight font-medium text-darken mt-1.5">
                    {t('account.change-your-password')}
                </h1>

                <div className="mobile:w-[343px] mt-[24px] relative">
                    <Formik initialValues={initialValues} onSubmit={onSubmit}>
                        <Form>
                            <InputPassword
                                name="oldPassword"
                                label={t('account.old-password')}
                                type="password"
                                validate={required(t)} />

                            <div className="mt-[13px]">
                                <InputPassword
                                    name="newPassword"
                                    label={t('account.new-password')}
                                    type="password"
                                    validate={required(t)} />
                            </div>

                            <div className="mt-[13px]">
                                <InputPassword
                                    name="newPassword2"
                                    label={t('account.confirm-password')}
                                    type="password"
                                    validate={required(t)} />
                            </div>

                            <ServerError error={error} />

                            <SecondButton
                                loading={loading}
                                text={t('account.save-password')}
                                className="mt-[27px] w-full h-[48px] font-semibold bg-rocket rounded-md text-14 text-pistachio hover:bg-spinach hover:ease-in hover:duration-200"
                            />
                        </Form>
                    </Formik>
                </div>
            </div>
        </AccountSidebar>
    )
}
