import React, { useCallback, useEffect, useLayoutEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import AccountSidebar from '../../components/account/AccountSidebar'
import { getUser } from '../../state/user/selectors'
import SecondButton from '../../components/common/SecondButton'
import PerkCard from '../../components/perk/PerkCard'
import { getPerkLists } from '../../state/perks/selectors'
import { getRecipe } from '../../state/recipe/selectors'
import RecipeCard from '../../components/recipe/RecipeCard'
import { fetchBookmarkedRecipe } from '../../state/recipe/actions'
import { fetchBookmarkedPerks } from '../../state/perks/actions'
import ArticleCard from '../../components/article/ArticleCard'
import { articles } from '../../state/articles/selectors'
import { fetchArticleMyBookmark } from '../../state/articles/actions'

export default function AccountOverview() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { user } = useSelector(getUser)
    const { bookmarkedPerks } = useSelector(getPerkLists)
    const { myBookmarks } = useSelector(getRecipe)
    const { myBookmarkArticle } = useSelector(articles)
    const [t] = useTranslation('global')

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `${window.location.pathname}${window.location.search}`, title: 'Account overview' })
    }, [])

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `${window.location.pathname}${window.location.search}`, title: 'Account overview' })
    }, [])

    const onRestartLoad = useCallback(() => {
        dispatch(fetchBookmarkedRecipe({ page: 1 }))
        dispatch(fetchBookmarkedPerks({ page: 1 }))
        dispatch(fetchArticleMyBookmark({ page: 1 }))
        // eslint-disable-next-line
    }, [])

    useLayoutEffect(() => {
        onRestartLoad()
        // eslint-disable-next-line
    }, [])

    return (
        <AccountSidebar>
            <div className="tablet:mb-[120px] mb-[80px] mobile:ml-10 mx-4 mobile:mr-10">
                <h1 className="heading-uppercase mobile:text-36 text-26 font tracking-tight font-medium text-darken mb-[24px]">
                    {t('account.Account overview')}
                </h1>

                <span className="text-22 tracking-tight text-darken font">
                    {t('account.My Info')}
                </span>

                <h1 className="font-normal text-16 text-grey01 mt-0.5">
                    {user.firstName} {user.lastName}
                </h1>

                <h1 className="font-normal text-16 text-grey01">
                    {user.email}
                </h1>

                <div className="flex mt-6">
                    <SecondButton
                        onClick={() => navigate('/me/account-settings')}
                        text={t('account.edit-details')}
                        className="mr-6 w-[136px] h-[48px] font-semibold bg-rocket rounded-md text-14 text-pistachio hover:bg-spinach hover:ease-in hover:duration-200"
                    />

                    <button onClick={() => navigate('/me/account-settings/change-password')} className="bg-mint hover:ease-in hover:duration-200 hover:text-spinach hover:bg-[#A7D49D] h-[48px] w-[180px] font-semibold border rounded-md text-14 text-marrow border-2 border-rocket">
                        {t('account.change-password')}
                    </button>
                </div>

                <div className="w-full h-[1px] bg-avocado mt-[40px]" />

                {bookmarkedPerks?.results?.length !== 0 && (
                    <>
                        <div>
                            <div className="flex justify-between mt-[40px]">
                                <span className="font-medium tracking-tight font text-18 text-darken">
                                    {t('account.your-perks')}
                                </span>

                                <button onClick={() => navigate('/lower/swaps/swaps-bookmark')} className="not-heading-font font-semibold tracking-tight underline underline-offset-2 text-14 text-rocket mt-1">
                                    {t('global.view-all')}
                                </button>
                            </div>

                            <div className="grid lg:gap-8 md:grid-cols-3 grid-cols-2 gap-4 mt-[9px]">
                                {bookmarkedPerks?.results?.slice(0, 3)?.map((item) => <PerkCard key={item.id} item={item} type="perk" />)}
                            </div>
                        </div>

                        <div className="w-full h-[1px] bg-avocado mt-[40px]" />
                    </>
                )}

                {myBookmarks?.results?.length !== 0 && (
                    <>
                        <div>
                            <div className="flex justify-between mt-[40px]">
                                <span className="font-medium tracking-tight font text-18 text-darken">
                                    {t('account.your-recipes')}
                                </span>

                                <button onClick={() => navigate('/lower/recipes/recipe-bookmark')} className="not-heading-font font-semibold tracking-tight underline underline-offset-2 text-14 text-rocket mt-1">
                                    {t('global.view-all')}
                                </button>
                            </div>

                            <div className="grid lg:gap-8 md:grid-cols-3 grid-cols-2 gap-4 mt-[9px]">
                                {myBookmarks?.results?.slice(0, 3)?.map((item) => <RecipeCard key={item.id} item={item} type="recipe" />)}
                            </div>
                        </div>

                        <div className="w-full h-[1px] bg-avocado mt-[40px]" />
                    </>
                )}

                {myBookmarkArticle?.results?.length !== 0 && (
                    <>
                        <div>
                            <div className="flex justify-between mt-[40px]">
                                <span className="font-medium tracking-tight font text-18 text-darken">
                                    {t('account.your-articles')}
                                </span>

                                <button onClick={() => navigate('/learn/articles/article-bookmark')} className="font-semibold not-heading-font tracking-tight underline underline-offset-2 text-14 text-rocket mt-1">
                                    {t('global.view-all')}
                                </button>
                            </div>

                            <div className="grid lg:gap-8 md:grid-cols-3 grid-cols-2 gap-4 mt-[9px]">
                                {myBookmarkArticle?.results?.slice(0, 3)?.map((item) => <ArticleCard key={item.id} item={item} type="article" />)}
                            </div>
                        </div>

                        <div className="w-full h-[1px] bg-avocado mt-[40px]" />
                    </>
                )}
            </div>
        </AccountSidebar>
    )
}
